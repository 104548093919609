<template>
    <div v-if="cell"
        :class="['bg-neutral-800', 'rounded-lg', 'shadow-md', 'text-white', 'flex', 'cursor-pointer', 'hover:shadow-lg', 'transition-shadow', 'duration-300', 'min-h-[400px]', isMobile ? 'flex-col' : 'flex-row']">
        <!-- Left side: Cell information -->
        <div v-if="cellStats" :class="[isMobile ? 'w-full' : 'w-1/2', 'pr-4', 'p-4', 'flex', 'flex-col']">
            <div class="flex items-center mb-4">
                <BackButton class="mr-2" />
                <h2 class="text-xl font-black font-lato capitalize">{{ cell.type }} {{ cell.name }}</h2>
            </div>

            <div class="grid grid-cols-3 gap-4 mb-6">
                <div v-for="(stat, index) in cellStats" :key="index" class="flex flex-col bg-black rounded-lg p-4">
                    <span class="text-2xl font-black font-lato">
                        <template v-if="stat.label === 'Bleaching'">
                            <span v-html="formatBleaching(stat.value)"></span>
                        </template>
                        <template v-else>
                            {{ formatPercentage(stat.value) }}
                        </template>
                    </span>
                    <span class="text-sm text-neutral-400">{{ stat.label }}</span>
                </div>
            </div>

            <div class="flex justify-between items-end mt-auto">
                <div class="text-sm text-neutral-400">
                    <p>{{ formatFirestoreTimestamp(lastHealth.timestamp) }}</p>
                </div>
                
                <a :href="externalPhotogrammetryUrl" target="_blank" rel="noopener" class="bg-[#27bdf4] hover:bg-[#1ca8dc] text-white font-bold py-2 px-4 rounded flex items-center">
                    <span>Photogrammetry Toolkit</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                </a>
            </div>
        </div>

        <!-- Right side: Photogrammetry -->
        <div :class="[isMobile ? 'w-full mt-4' : 'w-1/2']">
            <Photogrammetry :url="url" :modelTransform="modelTransform" />
        </div>
    </div>
</template>



<script>
import { db_resto } from '../main';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import Photogrammetry from './Photogrammetry.vue';
import BackButton from './utils/BackButton.vue';

export default {
    name: 'OutplantCell',
    components: {
        Photogrammetry,
        BackButton
    },
    props: {
        branchId: { type: String, required: true },
        outplantSiteId: { type: String, required: true },
        cellId: { type: String, required: true }
    },
    data() {
        return {
            cell: null,
            bucket: 'https://storage.googleapis.com/restoration-ios.appspot.com',
        };
    },
    mounted() {
        this.fetchOutplantCellData()
    },
    watch: {
        cellId() {
            this.cell = null;
            this.fetchOutplantCellData();
        }
    },
    computed: {
        url() {
            return this.bucket + '/coral-gardeners/' + this.lastHealth.photogrammetryPath;
        },
        modelTransform() {
            return this.lastHealth.modelTransform || null;
        },
        lastHealth() {
            if (!this.cell || !this.cell.healths || this.cell.healths.length === 0) {
                return null;
            }
            
            // Sort healths by timestamp in descending order (most recent first)
            const sortedHealths = [...this.cell.healths].sort((a, b) => {
                const timestampA = a.timestamp instanceof Timestamp ? a.timestamp.toDate().getTime() : 0;
                const timestampB = b.timestamp instanceof Timestamp ? b.timestamp.toDate().getTime() : 0;
                return timestampB - timestampA; // Descending order
            });
            
            return sortedHealths[0]; // Return most recent health
        },
        cellStats() {
            if (!this.lastHealth || !this.lastHealth.cellMonitoring) return [];
            const monitoring = this.lastHealth.cellMonitoring;
            return [
                { label: 'Bleaching', value: monitoring.bleach },
                { label: 'Coral Cover', value: monitoring.cover },
                { label: 'Survival', value: monitoring.survival }
            ];
        },
        isMobile() {
            return window.innerWidth < 768;
        },
        lastHealthIndex() {
            if (!this.cell || !this.cell.healths) return 0;
            
            // Find index of the lastHealth in the original healths array
            return this.cell.healths.findIndex(health => {
                if (!health.timestamp || !this.lastHealth.timestamp) return false;
                
                const healthTime = health.timestamp instanceof Timestamp ? 
                    health.timestamp.toDate().getTime() : 0;
                const lastHealthTime = this.lastHealth.timestamp instanceof Timestamp ? 
                    this.lastHealth.timestamp.toDate().getTime() : 0;
                
                return healthTime === lastHealthTime;
            });
        },
        externalPhotogrammetryUrl() {
            return `https://photogrammetry.coralgardeners.org/${this.branchId}/outplant/${this.outplantSiteId}/${this.cellId}/${this.lastHealthIndex}`;
        }
    },
    methods: {
        async fetchOutplantCellData() {
            try {
                const cellRef = doc(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'OutplantSites', this.outplantSiteId, 'OutplantCells', this.cellId);
                const docSnap = await getDoc(cellRef);
                if (docSnap.exists()) {
                    this.cell = { id: docSnap.id, ...docSnap.data() };
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching outplant cell data:", error);
            }
        },
        formatBleaching(value) {
            if (value === undefined || value === null || isNaN(Number(value))) {
                return '-';
            }
            return `${Math.round(value)}<span class="text-sm text-neutral-400">/6</span>`;
        },
        formatPercentage(value) {
            if (value === undefined || value === null || isNaN(Number(value))) {
                return '-';
            }
            return (value * 1).toFixed(0) + '%';
        },
        formatFirestoreTimestamp(timestamp) {
            if (timestamp instanceof Timestamp) {
                return new Date(timestamp.toDate()).toLocaleDateString();
            }
            return 'Invalid Date';
        }
    },
}
</script>

<style scoped>
/* Add any additional styles here */
</style>