<template>
    <div class="mx-auto">
        <div class="flex items-center mb-4">
            <BackButton class="mr-1" />
            <h2 v-if="branch" class="text-2xl font-black font-lato">{{ branch.name }}</h2>
            <div class="ml-2 relative">
                <button @click="toggleDropdown('branch')"
                    class="text-white hover:text-gray-300 transition-colors duration-300">
                    <i class="mdi mdi-dots-vertical text-xl"></i>
                </button>
                <div v-if="showDropdown === 'branch'"
                    class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem"
                            @click.prevent="navigateToManageCorals">
                            <i class="mdi mdi-family-tree mr-2"></i>Manage Corals
                        </a>
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem"
                            @click.prevent="navigateToManageFish">
                            <i class="mdi mdi-fish mr-2"></i>Manage Fish
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="branch" class="mb-4 z-10">
            <BranchOverview :branch="branch" :nurseries="nurseries" :outplantSites="outplantSites"
                :controlSites="controlSites" :loaded="dataLoaded" />
        </div>

        <!-- <AIReefresh v-if="branchData && user" :user="user" :currentData="branchData" /> -->

        <!-- <BranchNature v-if="branchData" :branchId="branchId" /> -->
        <!-- <BranchWeather v-if="branchData" :branchId="branchId" /> -->

        <BranchSchedule v-if="branchData" :branchId="branchId" :type="'nursery'" />

        <div class="flex items-center mb-4 ml-2 mt-12 relative">
            <h2 class="text-2xl font-black font-lato">Nurseries
                <span class="rounded-full text-lg bg-white text-black px-2 ml-2">{{ nurseries.length }}</span>
            </h2>
            <div class="ml-2 relative">
                <button @click="toggleDropdown('nursery')"
                    class="text-white hover:text-gray-300 transition-colors duration-300">
                    <i class="mdi mdi-dots-vertical text-xl"></i>
                </button>
                <div v-if="showDropdown === 'nursery'"
                    class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem"
                            @click.prevent="navigateToAddNew('nursery')">
                            <i class="mdi mdi-plus mr-2"></i>Add New Nursery
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid mb-4">
            <NurseryCard v-for="nursery in nurseries" :key="nursery.id" :branchId="branchId" :nursery="nursery" />
        </div>

        <div class="flex items-center mb-4 mt-12 ml-2 relative">
            <h2 class="text-2xl font-black font-lato">Outplant Sites
                <span class="rounded-full text-lg bg-white text-black px-2 ml-2">{{ outplantSites.length }}</span>
            </h2>

            <div class="ml-2 relative">
                <button @click="toggleDropdown('outplant')"
                    class="text-white hover:text-gray-300 transition-colors duration-300">
                    <i class="mdi mdi-dots-vertical text-xl"></i>
                </button>
                <div v-if="showDropdown === 'outplant'"
                    class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem"
                            @click.prevent="navigateToAddNew('outplant')">
                            <i class="mdi mdi-plus mr-2"></i>Add New Outplant Site
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- <BranchSchedule v-if="branchData" :branchId="branchId" :type="'outplant'" /> -->

        <div class="grid">
            <OutplantSiteCard v-for="site in outplantSites" :key="site.name" :branchId="branchId" :site="site" />
        </div>

        <div class="flex items-center my-4 relative">
            <h2 class="text-2xl font-black font-lato">Control Sites
                <span class="rounded-full text-lg bg-white text-black px-2 ml-2">{{ sortedControlSites.length }}</span>
            </h2>
            <div class="ml-2 relative">
                <button @click="toggleDropdown('control')"
                    class="text-white hover:text-gray-300 transition-colors duration-300">
                    <i class="mdi mdi-dots-vertical text-xl"></i>
                </button>
                <div v-if="showDropdown === 'control'"
                    class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem"
                            @click.prevent="navigateToAddNew('control')">
                            <i class="mdi mdi-plus mr-2"></i>Add New Control Site
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">
            <ControlSiteCard v-for="site in sortedControlSites" :key="site.name" :branchId="branchId" :site="site" />
        </div>
    </div>
</template>

<script>
import { collection, getDocs, query, where, updateDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db_resto } from '../main.js';
import BranchOverview from './BranchOverview.vue';
import AIReefresh from './AIReefresh.vue';
import NurseryCard from './NurseryCard.vue';
import OutplantSiteCard from './OutplantSiteCard.vue';
import ControlSiteCard from './ControlSiteCard.vue';
import AddNewCard from './utils/AddNewCard.vue';
import BranchSchedule from './BranchSchedule.vue';
import BranchNature from './BranchNature.vue';
import BranchWeather from './BranchWeather.vue';
import BackButton from './utils/BackButton.vue';

export default {
    name: 'Branch',
    components: {
        NurseryCard,
        OutplantSiteCard,
        BranchOverview,
        AIReefresh,
        AddNewCard,
        BranchSchedule,
        BranchNature,
        ControlSiteCard,
        BranchWeather,
        BackButton
    },
    props: {
        branchId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            user: null,
            branch: null,
            branchData: null,
            showDropdown: null,
            nurseries: [],
            outplantSites: [],
            controlSites: [],
            dataLoaded: false,
        };
    },
    mounted() {
        this.fetchAllData();
        this.fetchUser();
    },
    watch: {
        branchId() {
            // clear previous data
            this.branch = null;
            this.branchData = null;
            this.nurseries = [];
            this.outplantSites = [];
            this.controlSites = [];
            this.fetchAllData();
        }
    },
    computed: {
        sortedControlSites() {
            return this.controlSites.sort((a, b) => a.name.localeCompare(b.name));
        }
    },
    methods: {
        fetchUser() {
            const auth = getAuth();
            this.user = auth.currentUser;
        },
        async setUserLatest(data) {
            const usersRef = collection(db_resto, 'Users');
            try {
                const q = query(usersRef, where("email", "==", this.user.email));
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    console.log('No matching user document found');
                    return;
                }
                const userDoc = querySnapshot.docs[0];
                await updateDoc(userDoc.ref, {
                    lastData: {
                        data: JSON.stringify(data),
                        last_data_timestamp: serverTimestamp()
                    }
                });
            } catch (error) {
                console.log('Error updating user profile:', error);
            }
        },
        async fetchAllData() {
            await Promise.all([
                this.fetchBranch(),
                this.fetchNurseries(),
                this.fetchOutplantSites(),
                this.fetchControlSites(),
                this.fetchData()
            ]);
            this.mergeApiData();
        },
        async fetchBranch() {
            try {
                const branchRef = doc(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId);
                const docSnapshot = await getDoc(branchRef);
                if (docSnapshot.exists()) {
                    this.branch = {id: docSnapshot.id, ...docSnapshot.data(), data: null};
                } else {
                    console.log('No matching branch document found');
                }
            } catch (error) {
                console.log('Error fetching branch data from Firestore:', error);
            }
        },
        async fetchNurseries() {
            try {
                // Fetch nurseries from Firestore
                const nurseriesRef = collection(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'Nurseries');
                const querySnapshot = await getDocs(nurseriesRef);
                this.nurseries = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), data: null }));
                
                // Sort nurseries
                this.nurseries.sort((a, b) => a.name.localeCompare(b.name));
            } catch (error) {
                console.log('Error fetching nurseries data from Firestore:', error);
                this.nurseries = [];
            }
        },
        async fetchOutplantSites() {
            try {
                const outplantSitesRef = collection(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'OutplantSites');
                const querySnapshot = await getDocs(outplantSitesRef);
                this.outplantSites = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), data: null }));
                
                // Sort outplant sites
                this.outplantSites.sort((a, b) => a.name.localeCompare(b.name));
            } catch (error) {
                console.log('Error fetching outplant sites data from Firestore:', error);
                this.outplantSites = [];
            }
        },
        async fetchControlSites() {
            try {
                const controlSitesRef = collection(db_resto, 'Orgs', 'coral-gardeners', 'Branches', this.branchId, 'ControlSites');
                const querySnapshot = await getDocs(controlSitesRef);
                this.controlSites = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), data: null }));
            } catch (error) {
                console.log('Error fetching control sites data from Firestore:', error);
                this.controlSites = [];
            }
        },
        async fetchData() {
            try {
                const response = await fetch(`https://data.coralgardeners.org/data/branch?org=coral-gardeners&branch=${this.branchId}&pwd=showmethedata`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data.error) return;
                this.branchData = data;
                this.setUserLatest(this.branchData);
            } catch (error) {
                console.log('Error fetching data from API:', error);
            }
        },
        mergeApiData() {
            if (this.branchData) {
                const apiData = this.branchData.branch;
                this.branch = { ...this.branch, data: apiData };
            }
            if (this.branchData?.branch?.nursery_info[0]) {
                const apiData = this.branchData.branch.nursery_info[0];
                this.nurseries = this.nurseries.map(nursery => {
                    const nurseryData = apiData.find(n => n.nurseryID === nursery.id) || null;
                    return { ...nursery, data: nurseryData };
                });
            }
            if (this.branchData?.branch?.outplant_info) {
                const apiData = this.branchData.branch.outplant_info;
                this.outplantSites = this.outplantSites.map(site => {
                    const siteData = apiData.find(s => s.outplantID === site.id) || null;
                    return { ...site, data: siteData };
                });
            }
            if (this.branchData?.branch?.controls_info) {
                const apiData = this.branchData.branch.controls_info;
                this.controlSites = this.controlSites.map(site => {
                    const siteData = apiData.find(s => s.controlID === site.id) || null;
                    return { ...site, data: siteData };
                });
            }
            this.dataLoaded = true;
        },
        navigateToAddNew(type) {
            this.$router.push({
                name: type === 'nursery' ? 'AddNursery' : 'AddSite',
                params: {
                    branchId: this.branchId,
                    type: type,
                    loc: [this.branchData.branch.lat, this.branchData.branch.lon],
                },
            });
        },
        toggleDropdown(type) {
            this.showDropdown = this.showDropdown === type ? null : type;
        },
        navigateToManageCorals() {
            this.$router.push({
                name: 'ManageCorals',
                params: { branchId: this.branchId }
            });
        },
        navigateToManageFish() {
            this.$router.push({
                name: 'ManageFish',
                params: { branchId: this.branchId }
            });
        }
    }
}
</script>

<style>

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 16px;
}
</style>