<template>
    <div>
        <div class='grid gap-2'>
            <NurseryProgress v-if="Object.keys(data.nursery.summary).length > 0" :branchId="branchId" :nurseryId="nurseryId" :data="data" :class="isMobile ? 'mb-4' : ''" />
            <NurseryDiversity v-if="Object.keys(data.nursery.summary).length > 0" :data="data" :class="isMobile ? 'mb-4' : ''" />
            <NurseryChart 
                v-if="Object.keys(data.nursery.history.alive).length > 0" 
                :data="data" 
                type="alive"
                chartTitle="Survival" 
                unit="%" 
                percent 
                :maxY="100" 
                :class="isMobile ? 'mb-4' : ''" 
                @expand-chart="openModal"
                @close-chart="closeModal"
            />
            <NurseryChart 
                v-if="Object.keys(data.nursery.history.EVI).length > 0" 
                :data="data" 
                type="EVI" 
                chartTitle="Growth"
                unit="cm³" 
                :class="isMobile ? 'mb-4' : ''" 
                @expand-chart="openModal"
                @close-chart="closeModal"
            />
            <NurseryChart 
                v-if="Object.keys(data.nursery.history.bleach).length > 0" 
                :data="data" 
                type="bleach" 
                chartTitle="Bleaching" 
                flipTrend 
                :maxY="6" 
                :class="isMobile ? 'mb-4' : ''" 
                @expand-chart="openModal"
                @close-chart="closeModal"
            />
            <NurseryChart 
                v-if="Object.keys(data.nursery.history.health).length > 0" 
                :data="data" 
                type="health"
                chartTitle="Health" 
                flipTrend 
                :maxY="6" 
                :class="isMobile ? 'mb-4' : ''" 
                @expand-chart="openModal"
                @close-chart="closeModal"
            />
        </div>
        
        <!-- Add the ComboChart here -->
        <div class="mt-4"> 
            <ComboChart 
                v-if="Object.keys(data.nursery.history).some(key => Object.keys(data.nursery.history[key]).length > 0)"
                :data="data" 
                chartTitle="COMBINED" 
            />
        </div>

        <div>
            <NurseryMonitoring v-if="Object.keys(data.nursery.history.type).length > 0" :data="data" class="mt-4" />
        </div>

        <!-- Modal for Expanded Chart -->
        <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
            <div class="modal-content" @click.stop>
                <NurseryChart 
                    class="h-[500px]"
                    :data="expandedChart.data" 
                    :type="expandedChart.type"
                    :chartTitle="expandedChart.chartTitle"
                    :unit="expandedChart.unit"
                    :percent="expandedChart.percent"
                    :flipTrend="expandedChart.flipTrend"
                    :maxY="expandedChart.maxY"
                    :isExpanded="true"
                    @close-chart="closeModal"
                />
            </div>
        </div>
    </div>
</template>

<script>
import NurseryDiversity from './NurseryDiversity.vue';
import NurseryMonitoring from './NurseryMonitoring.vue';
import NurseryProgress from './NurseryProgress.vue';
import NurseryChart from './NurseryChart.vue';
import ComboChart from './ComboChart.vue';

export default {
    name: 'NurseryOverview',
    components: {
        NurseryDiversity,
        NurseryMonitoring,
        NurseryProgress,
        NurseryChart,
        ComboChart
    },
    props: {
        branchId: {
            type: String,
            required: true
        },
        nurseryId: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isModalOpen: false,
            expandedChart: {}
        };
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        }
    },
    methods: {
        // Method to open the modal with the expanded chart
        openModal(chartData) {
            this.expandedChart = chartData;
            this.isModalOpen = true;
        },
        // Method to close the modal
        closeModal() {
            this.isModalOpen = false;
            this.expandedChart = {};
        }
    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background: #1a202c; /* Dark background */
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;
}
</style>