<template>
    <div @click="navigateToCell"
        class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col min-w-[200px] min-h-[150px] cursor-pointer hover:shadow-lg transition-shadow duration-300 relative">
        <h3 class="text-xl font-black font-lato mb-2">Cell {{ cell.name }}</h3>
        <i v-if="hasPhotogrammetry" class="mdi mdi-cube-outline text-xl absolute top-4 right-4" :class="{ 'text-green-500': isModelAnnotated, 'text-white': !isModelAnnotated }" />
        
        <!-- Cell Stats -->
        <div v-if="cellStats.length > 0" class="grid grid-cols-3 gap-2 mt-2">
            <div v-for="(stat, index) in cellStats" :key="index" class="flex flex-col bg-neutral-800 rounded p-2">
                <span class="text-lg font-black font-lato">
                    <template v-if="stat.label === 'Bleaching'">
                        <span v-html="formatBleaching(stat.value)"></span>
                    </template>
                    <template v-else>
                        {{ formatPercentage(stat.value) }}
                    </template>
                </span>
                <span class="text-xs text-neutral-400">{{ stat.label }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OutplantCellCard',
    props: {
        branchId: {
            type: String,
            required: true
        },
        outplantSiteId: {
            type: String,
            required: true
        },
        cell: {
            type: Object,
            required: true
        },
    },
    computed: {
        hasPhotogrammetry() {
            return this.cell.healths && this.cell.healths.some(health => health.photogrammetryPath);
        },
        isModelAnnotated() {
            if (!this.cell.healths || this.cell.healths.length === 0) return false;
            
            const sortedHealths = [...this.cell.healths].sort((a, b) => 
                new Date(b.timestamp) - new Date(a.timestamp)
            );
            
            return sortedHealths[0].modelAnnotated === true;
        },
        latestHealth() {
            if (!this.cell.healths || this.cell.healths.length === 0) return null;
            
            const sortedHealths = [...this.cell.healths].sort((a, b) => 
                new Date(b.timestamp) - new Date(a.timestamp)
            );
            
            return sortedHealths[0];
        },
        cellStats() {
            if (!this.latestHealth || !this.latestHealth.cellMonitoring) return [];
            
            const monitoring = this.latestHealth.cellMonitoring;
            const stats = [
                { label: 'Bleaching', value: monitoring.bleach },
                { label: 'Coral Cover', value: monitoring.cover },
                { label: 'Survival', value: monitoring.survival }
            ];
            
            // Filter out stats with NaN, undefined, or null values
            return stats.filter(stat => 
                stat.value !== undefined && 
                stat.value !== null && 
                !isNaN(Number(stat.value))
            );
        }
    },
    methods: {
        navigateToCell() {
            this.$router.push({
                name: 'OutplantCell',
                params: {
                    branchId: this.branchId,
                    outplantSiteId: this.outplantSiteId,
                    cellId: this.cell.id,
                }
            });
        },
        formatBleaching(value) {
            if (value === undefined || value === null || isNaN(Number(value))) {
                return '-';
            }
            return `${Math.round(value)}<span class="text-sm text-neutral-400">/6</span>`;
        },
        formatPercentage(value) {
            if (value === undefined || value === null || isNaN(Number(value))) {
                return '-';
            }
            return (value * 1).toFixed(0) + '%';
        }
    }
}
</script>