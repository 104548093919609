<template>
    <div v-if="nursery">
        <!-- Add background image container -->
        <div v-if="(nursery.cover_image || (nursery.image_urls && nursery.image_urls.length > 0)) && !editDataMode && !editPhotosMode"
             class="absolute top-0 left-0 w-full h-[400px] -z-10">
            <div class="absolute inset-0">
                <img :src="nursery.cover_image || nursery.image_urls[0]"
                     class="w-full h-full object-cover mask-fade"
                     :alt="nursery.cover_image ? 'Cover image' : 'Nursery image 1'">
            </div>
        </div>

        <div class="flex items-center mb-4 ml-1">
            <BackButton class="mr-1" />
            <h1 class="text-2xl font-black font-lato mr-2">{{ nursery.name }}</h1>
            <div class="relative">
                <button @click="toggleMenu" class="text-white hover:text-gray-300 transition-colors duration-300">
                    <i class="mdi mdi-dots-vertical text-xl"></i>
                </button>
                <div v-if="showMenu"
                    class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
                    <div class="py-1 text-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem"
                            @click.prevent="editNurseryData">
                            <i class="mdi mdi-pencil mr-2"></i>Edit Data
                        </a>
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem"
                            @click.prevent="editNurseryPhotos">
                            <i class="mdi mdi-image-edit mr-2"></i>Edit Photos
                        </a>
                    </div>
                </div>
            </div>
            <!-- Location Badge -->
            <a v-if="data && data.nursery && data.nursery.summary && data.nursery.summary.lat && data.nursery.summary.lon"
               :href="`https://www.google.com/maps?q=${data.nursery.summary.lat},${data.nursery.summary.lon}`"
               target="_blank"
               rel="noopener noreferrer"
               class="bg-black px-3 py-1 rounded-lg flex items-center text-xs ml-2 cursor-pointer hover:bg-gray-800 transition-colors duration-200">
                <i class="mdi mdi-map-marker mr-1"></i>
                <span>Lat: {{ data.nursery.summary.lat.toFixed(4) }}, Lon: {{ data.nursery.summary.lon.toFixed(4) }}</span>
            </a>
        </div>

        <NurseryEdit v-if="editDataMode" :branchId="branchId" :nurseryId="nurseryId" @update="editNurseryData" />
        <NurseryEditPhotos 
            v-if="editPhotosMode" 
            :branchId="branchId" 
            :nurseryId="nurseryId" 
            :nursery="nursery"
            @update="fetchNurseryData"
            @close="editPhotosMode = false"
        />

        <div v-if="!editDataMode && !editPhotosMode">
            <!-- Only add margin-top when there's an image -->
            <div :class="{ 'mt-[300px]': nursery.cover_image || (nursery.image_urls && nursery.image_urls.length > 0) }">
                <ScheduleCard class="mb-4" :branchId="branchId" :siteId="nurseryId" :type="'nursery'" />

                <NurseryOverview v-if="data" :branchId="branchId" :nurseryId="nurseryId" :data="data" />

                <div class="bg-black p-4 mt-4 rounded-lg">
                    <h2 class="text-2xl font-black font-lato mb-4">Structures
                        <span class="rounded-full text-lg bg-white text-black px-2 ml-2">{{ sortedStructures.length
                            }}</span>
                    </h2>
                    <div class="structures-grid">
                        <StructureCard v-for="structure in sortedStructures" :key="structure.id" :structure="structure"
                            :fragments="fragmentsByStructure[structure.id]" :branchId="branchId" :nurseryId="nurseryId" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db_resto } from '../main';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import StructureCard from './StructureCard.vue';
import NurseryOverview from './NurseryOverview.vue';
import ScheduleCard from './ScheduleCard.vue'
import AINurserySummary from './AINurserySummary.vue';
import NurseryEdit from './NurseryEdit.vue';
import NurseryEditPhotos from './NurseryEditPhotos.vue';
import BackButton from './utils/BackButton.vue';

export default {
    name: 'Nursery',
    components: {
        StructureCard,
        NurseryOverview,
        ScheduleCard,
        AINurserySummary,
        NurseryEdit,
        NurseryEditPhotos,
        BackButton,
    },
    props: {
        branchId: {
            type: String,
            required: true
        },
        nurseryId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            nursery: null,
            fragments: [],
            structures: [],
            data: null,
            editMode: false,
            showMenu: false,
            editDataMode: false,
            editPhotosMode: false,
        };
    },
    mounted() {
        // this.fetchNurseryData();
        // this.fetchData();
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.fetchNurseryData(); // Firestore
                this.fetchData(); // DE API
            }
        }
    },
    computed: {
        fragmentsByStructure() {
            return this.structures.reduce((acc, structure) => {
                acc[structure.id] = this.fragments.filter(f => f.location.structureID === structure.id);
                return acc;
            }, {});
        },
        sortedStructures() {
            return [...this.structures].sort((a, b) => a.position - b.position);
        }
    },
    methods: {
        editNurseryData() {
            this.editDataMode = !this.editDataMode;
            this.editPhotosMode = false;
            this.showMenu = false;
        },
        editNurseryPhotos() {
            this.editPhotosMode = !this.editPhotosMode;
            this.editDataMode = false;
            this.showMenu = false;
        },
        async fetchData() {
            try {
                const response = await fetch(`https://data.coralgardeners.org/data/nursery?org=coral-gardeners&nursery=${this.nurseryId}&pwd=showmethedata`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                this.data = await response.json();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        async fetchNurseryData() {
            try {
                const nurseryRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}`);
                const nurseryDoc = await getDoc(nurseryRef);

                if (nurseryDoc.exists()) {
                    this.nursery = { id: nurseryDoc.id, ...nurseryDoc.data() };
                    await Promise.all([
                        this.fetchStructures(),
                        this.fetchFragments()
                    ]);
                } else {
                    console.log('No such nursery!');
                }
            } catch (error) {
                console.error("Error fetching nursery data:", error);
            }
        },
        async fetchStructures() {
            try {
                const structuresRef = collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}/Structures`);
                const structuresSnapshot = await getDocs(structuresRef);

                this.structures = structuresSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

            } catch (error) {
                console.error("Error fetching structures:", error);
            }
        },
        async fetchFragments() {
            try {
                const fragmentsRef = collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Fragments`);
                const q = query(fragmentsRef, where("location.nurseryID", "==", this.nurseryId));
                const querySnapshot = await getDocs(q);

                this.fragments = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

            } catch (error) {
                console.error("Error fetching fragments:", error);
            }
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
    }
}
</script>

<style scoped>
.mask-fade {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.structures-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}
</style>