<template>
    <div
        :class="['bg-neutral-800', 'rounded-lg', 'shadow-md', 'text-white', 'flex', 'cursor-pointer', 'hover:shadow-lg', 'transition-shadow', 'duration-300', 'min-h-[400px]', isMobile ? 'flex-col' : 'flex-row']">
        <!-- Left side: Branch information -->
        <div class="w-full lg:w-1/2 p-4 flex flex-col">
            <template v-if="branch.data">
                <!-- Top half: 3x2 grid of statistics -->
                <div class="grid grid-cols-3 gap-4">
                    <div v-for="(stat, index) in combinedStats" :key="index" class="flex flex-col bg-black p-2 rounded-lg">
                        <span class="text-xl font-black font-lato ml-2">
                            <span v-if="(stat.key === 'YTD Outplanted' || stat.key === 'YTD Seeded')">
                                <span class="mdi mdi-trending-up"> {{ stat.value }}</span>
                            </span>
                            <span v-else>{{ stat.value }}</span>
                        </span>
                        <span class="text-sm ml-2 text-neutral-400">{{ stat.label }}</span>
                    </div>
                </div>

                <!-- Bottom half: Fraction Alive gauge and Top Species table -->
                <div class="flex flex-col lg:flex-row justify-between gap-4 mt-auto">
                    <!-- Fraction Alive gauge -->
                    <div class="w-full lg:w-1/3 flex flex-col items-center bg-black p-4 rounded-lg mb-4 lg:mb-0 lg:mr-2 mt-8 lg:mt-0">
                        <h3 class="text-lg font-semibold mb-2">Survival</h3>
                        <svg class="w-24 h-24">
                            <circle cx="48" cy="48" r="44" fill="none" stroke="#333" stroke-width="8"
                                stroke-linecap="round" />
                            <circle cx="48" cy="48" r="44" fill="none" stroke="#27bdf4" stroke-width="8"
                                stroke-linecap="round" :stroke-dasharray="`${fractionAlive * 276.32} 276.32`"
                                transform="rotate(-90 48 48)" />
                            <text x="48" y="48" text-anchor="middle" dominant-baseline="central" fill="white" font-size="20"
                                font-weight="black">
                                {{ formatPercentage(branch.data['Fraction Alive']) }}
                            </text>
                        </svg>
                    </div>

                    <!-- Top Species table and Shannon Index badge -->
                    <div class="w-full lg:w-2/3 bg-black p-4 rounded-lg relative">
                        <div class="flex justify-between items-center mb-2">
                            <h3 class="text-lg font-semibold">Diversity</h3>
                            <span class="bg-white text-black px-2 py-1 rounded-lg font-black font-lato text-sm">
                                {{ (shannonIndex * 100).toFixed(0) }}%
                            </span>
                        </div>
                        <ul>
                            <li v-for="(fraction, species) in topSpecies" :key="species"
                                class="flex justify-between items-center mb-1">
                                <span class="text-xs truncate text-neutral-400">{{ species }}</span>
                                <span class="text-xs font-medium">{{ (fraction * 100).toFixed(0) }}%</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="flex flex-col justify-center items-center h-full">
                    <p class="text-center text-neutral-400">
                        <span class="mdi mdi-alert-circle-outline mr-1"></span>Branch data unavailable
                    </p>
                </div>
            </template>
        </div>

        <!-- Right side: Map -->
        <div class="w-full lg:w-1/2 relative">
            <div :class="[
                'w-full z-10',
                isMobile ? 'rounded-bl-lg rounded-br-lg h-64' : 'h-full rounded-tr-lg rounded-br-lg'
            ]" ref="map"></div>
            <button @click="navigateToMap" class="absolute top-2 right-2 bg-primary text-black px-2 py-1 rounded z-10">
                <span class="mdi mdi-fullscreen"></span>
            </button>
        </div>
    </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import L from 'leaflet';
import 'leaflet.markercluster';

export default {
    name: 'BranchOverview',
    props: {
        branch: {
            type: Object,
            required: true,
        },
        nurseries: {
            type: Array,
            required: true,
        },
        outplantSites: {
            type: Array,
            required: true,
        },
        controlSites: {
            type: Array,
            required: true,
        },
        loaded: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            map: null,
            markerClusterGroup: null,
        };
    },
    computed: {
        combinedStats() {
            if (!this.branch.data) return [];
            const currentYear = new Date().getFullYear();
            return [
                { key: 'Fragments', label: 'Total In Nurseries', value: this.formatNumber(this.branch.data.Fragments) },
                { key: 'Outplanted', label: 'Total Outplanted', value: this.formatNumber(this.branch.data.Outplanted) },
                { key: 'Species/Genera', label: 'Species / Genera', value: `${this.formatNumber(this.branch.data.Species)} / ${this.formatNumber(this.branch.data.Genera)}` },
                { key: 'YTD Seeded', label: `${currentYear} Seeded`, value: this.formatNumber(this.branch.data['YTD Seeded']) },
                { key: 'YTD Outplanted', label: `${currentYear} Outplanted`, value: this.formatNumber(this.branch.data['YTD Outplanted']) },
                { key: 'Mother Colonies', label: 'Mother Colonies', value: this.formatNumber(this.branch.data['Mother Colonies']) },
            ];
        },
        topSpecies() {
            if (!this.branch || !this.branch.data.Species_Frac) return {};
            return Object.entries(this.branch.data.Species_Frac)
                .sort(([, a], [, b]) => b - a)
                .slice(0, 5)
                .reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                }, {});
        },
        shannonIndex() {
            if (!this.branch || !this.branch.data.Species_Frac) return 0;

            const speciesFrac = this.branch.data.Species_Frac;

            const S = Object.keys(speciesFrac).length;
            if (S === 0) return 0;

            // Get proportions directly (already normalized)
            const proportions = Object.values(speciesFrac);

            // Compute Shannon Index
            const H = proportions.reduce((acc, p) => {
                return acc - (p > 0 ? p * Math.log(p) : 0);
            }, 0);

            // Normalize Shannon Index by ln(S)
            const normalizedH = H / Math.log(S);

            return normalizedH;
        },
        fractionAlive() {
            return this.branch.data ? this.branch.data['Fraction Alive'] : 0;
        },
        isMobile() {
            return window.innerWidth < 768;
        },
    },
    watch: {
        loaded: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.$nextTick(() => {
                        this.initMap();
                    });
                }
            },
        },
    },
    beforeDestroy() {
        this.destroyMap();
    },
    methods: {
        extractGenus(species) {
            if (typeof species !== 'string') return null;
            const parts = species.split(' ');
            return parts.length > 0 ? parts[0] : null;
        },
        initMap() {
            if (this.mapInitialized || !this.$refs.map) {
                return;
            }

            this.destroyMap();

            this.map = L.map(this.$refs.map, {
                zoomControl: false,
                attributionControl: false,
            }).setView([0, 0], 2);

            const mapboxAccessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
            L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + mapboxAccessToken, {
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.map);

            this.markerClusterGroup = L.markerClusterGroup();
            this.map.addLayer(this.markerClusterGroup);

            this.fitMapBounds();
            this.addMarkers();

            this.mapInitialized = true;
        },
        destroyMap() {
            if (this.map) {
                this.map.off();
                this.map.remove();
                this.map = null;
            }
            this.mapInitialized = false;
        },
        fitMapBounds() {
            if (!this.map) return;

            const allLocations = [
                ...this.nurseries.map(nursery => ({ longitude: nursery.geoLocation.longitude, latitude: nursery.geoLocation.latitude })),
                ...this.outplantSites.map(site => ({ longitude: site.geoLocation.longitude, latitude: site.geoLocation.latitude })),
                ...this.controlSites.map(site => ({ longitude: site.geoLocation.longitude, latitude: site.geoLocation.latitude }))
            ].filter(location => location && location.latitude && location.longitude);

            if (allLocations.length === 0) {
                return;
            }

            const bounds = L.latLngBounds(allLocations.map(loc => [loc.latitude, loc.longitude]));
            this.map.fitBounds(bounds, { padding: [50, 50] });
        },
        addMarkers() {
            this.markerClusterGroup.clearLayers(); // Clear previous markers

            this.nurseries.forEach(nursery => {
                if (nursery.geoLocation.latitude && nursery.geoLocation.longitude) {
                    const marker = L.marker([nursery.geoLocation.latitude, nursery.geoLocation.longitude], {
                        icon: this.createCustomIcon(require('@/assets/icons/markers/nursery-marker.svg'))
                    })
                        .bindPopup(this.createPopupContent(nursery, 'nursery'));
                    this.markerClusterGroup.addLayer(marker); // Add marker to cluster group
                }
            });

            this.outplantSites.forEach(site => {
                if (site.geoLocation.latitude && site.geoLocation.longitude) {
                    const marker = L.marker([site.geoLocation.latitude, site.geoLocation.longitude], {
                        icon: this.createCustomIcon(require('@/assets/icons/markers/outplant-marker.svg'))
                    })
                        .bindPopup(this.createPopupContent(site, 'outplant'));
                    this.markerClusterGroup.addLayer(marker); // Add marker to cluster group
                }
            });

            this.controlSites.forEach(site => {
                if (site.geoLocation.latitude && site.geoLocation.longitude) {
                    const marker = L.marker([site.geoLocation.latitude, site.geoLocation.longitude], {
                        icon: this.createCustomIcon(require('@/assets/icons/markers/control-marker.svg'))
                    })
                        .bindPopup(this.createPopupContent(site, 'control'));
                    this.markerClusterGroup.addLayer(marker); // Add marker to cluster group
                }
            });
        },
        createCustomIcon(iconPath) {
            return L.divIcon({
                className: 'custom-div-icon',
                html: `<img src="${iconPath}" style="width: 40px; height: 40px;" />`,
                iconSize: [40, 40],
                iconAnchor: [20, 40]
            });
        },
        formatNumber(value) {
            return value ? value.toLocaleString() : 'N/A';
        },
        formatPercentage(value) {
            return `${(value * 100).toFixed(0)}%`;
        },
        formatCoordinates(lat, lon) {
            return `${lat.toFixed(4)}, ${lon.toFixed(4)}`;
        },
        createPopupContent(location, type) {
            const content = document.createElement('div');
            content.className = 'custom-popup';

            const title = document.createElement('h3');
            title.textContent = location.name;
            title.className = 'font-black font-lato mb-1';
            content.appendChild(title);

            const button = document.createElement('button');
            button.textContent = 'Open';
            button.className = 'bg-primary text-white font-black font-lato px-2 py-1 rounded text-xs';
            button.onclick = (e) => {
                e.stopPropagation();
                this.navigateToLocation(location, type);
            };
            content.appendChild(button);

            return content;
        },
        navigateToMap() {
            this.$router.push({
                name: 'BranchMap',
                params: { branchId: this.branch.id }
            });
        },
        navigateToLocation(location, type) {
            switch (type) {
                case 'nursery':
                    this.$router.push({
                        name: 'Nursery',
                        params: {
                            branchId: this.branch.id,
                            nurseryId: location.id
                        }
                    });
                    break;
                case 'outplant':
                    this.$router.push({
                        name: 'OutplantSite',
                        params: {
                            branchId: this.branch.id,
                            outplantSiteId: location.id
                        }
                    });
                    break;
                case 'control':
                    this.$router.push({
                        name: 'ControlSite',
                        params: {
                            branchId: this.branch.id,
                            controlSiteId: location.id
                        }
                    });
                    break;
            }
        },
    },
};
</script>

<style scoped>
.custom-div-icon {
    background: none;
    border: none;
}

.custom-popup {
    background-color: #1c1c1c;
    color: white;
    border-radius: 8px;
    padding: 10px;
    min-width: 150px;
}

:deep(.leaflet-popup-content-wrapper) {
    background-color: #1c1c1c;
    color: white;
    border-radius: 8px;
}

:deep(.leaflet-popup-tip) {
    background-color: #1c1c1c;
}

:deep(.leaflet-popup-close-button) {
    color: white;
}

/* Override default cluster styles with !important */
:deep(.marker-cluster-small) {
    background-color: rgba(39, 189, 244, 0.6) !important;
}

:deep(.marker-cluster-medium) {
    background-color: rgba(39, 189, 244, 0.6) !important;
}

:deep(.marker-cluster-large) {
    background-color: rgba(39, 189, 244, 0.6) !important;
}

/* Override the inner div background color */
:deep(.marker-cluster-small div),
:deep(.marker-cluster-medium div),
:deep(.marker-cluster-large div) {
    background-color: rgba(39, 189, 244, 0.6) !important;
}

/* Ensure text color is visible and set font to Lato */
:deep(.marker-cluster span) {
    color: #ffffff !important;
    font-family: 'Lato', sans-serif !important;
    font-size: 12px; /* Adjust font size if needed */
    line-height: 30px; /* Ensure text is vertically centered */
}
</style>
