<template>
    <div v-if="outplantSite">
        <div v-if="editMode">
            <SiteEdit :branchId="branchId" :siteId="outplantSiteId" :type="'outplant'" @update="editMode = false" />
        </div>
        <template v-else>
            <div class="flex items-center mb-4 ml-1">
                <BackButton class="mr-1" />
                <h1 class="text-2xl font-black font-lato mr-2">{{ outplantSite.name }}</h1>
                <div class="relative">
                    <button @click="toggleMenu" class="text-white hover:text-gray-300 transition-colors duration-300">
                        <i class="mdi mdi-dots-vertical text-xl"></i>
                    </button>
                    <div v-if="showMenu" class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
                        <div class="py-1 text-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem" @click.prevent="editSite">
                                <i class="mdi mdi-pencil mr-2"></i>Edit
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <OutplantOverview :site="outplantSite" :outplantCells="outplantCells" :overview="overview" :devices="devices"/>
            
            <!-- <h2 class="text-2xl font-black font-lato mb-4 mt-4 ml-1">Monitoring Schedule</h2>
            <ScheduleCard class="mb-4" :branchId="branchId" :siteId="outplantSiteId" :type="'outplant'" /> -->
            
            <h2 class="text-2xl font-black font-lato mb-4 mt-4 capitalize">Devices</h2>
            <div v-if="devices.length > 0" class="structures-grid">
                <DeviceCard v-for="device in devices" :key="device.id" :device="device" />
            </div>
            <h2 v-if="outplantCells.length > 0" class="text-2xl font-black font-lato mb-4 mt-4 capitalize">{{ outplantCells[0].type}}s
            </h2>
            <div class="structures-grid">
                <OutplantCellCard v-for="cell in sortedCells" :key="cell.id" :branchId="branchId" :outplantSiteId="outplantSiteId" :cell="cell" />
            </div>
        </template>
    </div>
</template>

<script>
import SiteEdit from './SiteEdit.vue';
import { db_resto } from '../main';
import { db_map } from '../main';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import OutplantOverview from './OutplantOverview.vue';
import OutplantCellCard from './OutplantCellCard.vue';
import DeviceCard from './DeviceCard.vue';
import ScheduleCard from './ScheduleCard.vue';
import BackButton from './utils/BackButton.vue';

export default {
    name: 'OutplantSite',
    components: {
        OutplantOverview,
        OutplantCellCard,
        DeviceCard,
        SiteEdit,
        ScheduleCard,
        BackButton
    },
    props: {
        branchId: String,
        outplantSiteId: String,
    },
    data() {
        return {
            overview: null,
            outplantSite: null,
            outplantCells: [],
            fragments: [],
            devices: [],
            showMenu: false,
            editMode: false,
        };
    },
    mounted() {
        this.fetchOverviewData();
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.fetchOutplantData();
            }
        }
    },
    computed: {
        sortedCells() {
            return this.outplantCells.sort((a, b) =>
                a.name.localeCompare(b.name, undefined, { numeric: true })
            );
        }
    },
    methods: {
        async fetchOverviewData() {
            try {
                const response = await fetch(`https://data.coralgardeners.org/data/branch?org=coral-gardeners&branch=${this.branchId}&pwd=showmethedata`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const global = await response.json()
                this.overview = global.branch.outplant_info.find(outplant => outplant.outplantID === this.outplantSiteId);
            } catch (error) {
                console.error('Error fetching data:', error);
            }

        },
        async fetchOutplantData() {
            try {
                // Clear existing data
                this.outplantSite = null;
                this.outplantCells = [];

                // Fetch outplant site data
                const outplantSiteRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/OutplantSites/${this.outplantSiteId}`);
                const outplantSiteDoc = await getDoc(outplantSiteRef);

                if (outplantSiteDoc.exists()) {
                    this.outplantSite = { id: outplantSiteDoc.id, ...outplantSiteDoc.data() };
                    if (this.outplantSite.reefos_id) this.getDeviceInfo(this.outplantSite.reefos_id);

                    // Fetch outplant cells
                    const outplantCellsRef = collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/OutplantSites/${this.outplantSiteId}/OutplantCells`);
                    const outplantCellsQuery = query(outplantCellsRef);

                    const querySnapshot = await getDocs(outplantCellsQuery);
                    querySnapshot.forEach((doc) => {
                        this.outplantCells.push({ id: doc.id, ...doc.data() });
                    });

                    // await this.fetchFragments(); <-- takes forever to load
                } else {
                    console.log('No such outplant site!');
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        async getDeviceInfo(reefosId) {
            try {
                const deviceRef = doc(db_map, `Cameras/${reefosId}`);
                const deviceDoc = await getDoc(deviceRef);

                if (deviceDoc.exists()) {
                    this.devices.push({ id: deviceDoc.id, ...deviceDoc.data() });
                } else {
                    console.log('No such device!');
                }
            } catch (error) {
                console.error("Error fetching device data:", error);
            }
        },
        async fetchFragments() {
            try {
                // Get all cell IDs for the current outplant site
                const cellIds = this.outplantCells.map(cell => cell.id);

                const fragmentsRef = collection(db_resto, "Orgs", "coral-gardeners", "Branches", this.branchId, "Fragments");
                const querySnapshot = await getDocs(fragmentsRef);

                // Filter fragments based on the cell IDs
                this.fragments = querySnapshot.docs
                    .filter(doc => {
                        const data = doc.data();
                        return data.outplantInfo && cellIds.includes(data.outplantInfo.outplantCellID);
                    })
                    .map(doc => ({ id: doc.id, ...doc.data() }));

            } catch (error) {
                console.error('Error fetching fragments:', error);
            }
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        editSite() {
            this.editMode = true;
            this.showMenu = false;
        },
        updateSite(updatedSite) {
            this.outplantSite = { ...this.outplantSite, ...updatedSite };
            this.editMode = false;
            // You might want to refresh data or update Firestore here
        },
        cancelEdit() {
            this.editMode = false;
        },
    }
}
</script>

<style scoped>
.structures-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}
</style>