<template>
  <div class="flex flex-col space-y-4">
    <BackButton class="mr-1" />
    <div class="bg-black rounded-lg shadow-md text-white p-2 px-4 flex flex-col min-w-[200px] relative" ref="container">

      <!-- Title and Year selector dropdown -->
      <div class="flex justify-between items-center">
        <h2 class="text-xl font-black font-lato">Timeline</h2>
        <select v-model="selectedYear" @change="onYearChange" class="bg-gray-800 text-white rounded px-2 py-1">
          <option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>

      <div class="timeline-container" :style="{ height: `${timelineHeight}px` }">
        <svg :key="svgKey" :width="timelineWidth" :height="timelineHeight" class="absolute top-0 left-0">
          <!-- Add this pattern definition for diagonal lines -->
          <defs>
            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
              <path d="M-1,1 l2,-2
                       M0,4 l4,-4
                       M3,5 l2,-2" style="stroke:#888; stroke-width:1" />
            </pattern>
          </defs>

          <!-- Grid lines -->
          <g>
            <!-- Horizontal row lines, including top line -->
            <line v-for="index in rows.length+1" :key="'row-line-' + (index - 1)" :x1="labelWidth"
              :y1="10 + (index - 1) * 25" :x2="timelineWidth - padding" :y2="10 + (index - 1) * 25" stroke="#333333"
              stroke-width="1" opacity="0.5" />

            <!-- Vertical month lines -->
            <line v-for="(month, index) in adjustedMonths" :key="'grid-' + month"
              :x1="labelWidth + (index * (timelineWidth - labelWidth - padding) / adjustedMonths.length)" :y1="10"
              :x2="labelWidth + (index * (timelineWidth - labelWidth - padding) / adjustedMonths.length)"
              :y2="10 + rows.length * 25" stroke="#333333" stroke-width="1" opacity="0.5" />
          </g>

          <!-- Add this rectangle for the gray overlay with diagonal lines -->
          <rect v-if="currentDateX !== null" :x="labelWidth" :y="10" :width="currentDateX - labelWidth"
            :height="timelineHeight - 30" fill="url(#diagonalHatch)" fill-opacity="0.4" />

          <!-- Row labels with rounded color bars -->
          <g v-for="(row, index) in [...rows]" :key="'row-' + index">
            <rect x="0" :y="10 + index * 25" width="3" height="20" rx="1.5"
              :fill="getEventColor({ level: index })" />
            <text x="8" :y="25 + index * 25" font-size="12" fill="white" dominant-baseline="middle">
              {{ row }}
            </text>
          </g>

          <!-- Timeline -->
          <line :x1="labelWidth" :y1="timelineHeight - 20" :x2="timelineWidth - padding" :y2="timelineHeight - 20"
            stroke="white" stroke-width="1" />

          <!-- Month ticks and labels -->
          <line v-for="(month, index) in adjustedMonths" :key="'tick-' + month + index"
            :x1="labelWidth + (index * (timelineWidth - labelWidth - padding) / adjustedMonths.length)"
            :y1="timelineHeight - 20"
            :x2="labelWidth + (index * (timelineWidth - labelWidth - padding) / adjustedMonths.length)"
            :y2="timelineHeight - 25" stroke="white" stroke-width="1" />

          <text v-for="(month, index) in adjustedMonths" :key="month + index"
            :x="labelWidth + (index * (timelineWidth - labelWidth - padding) / adjustedMonths.length)"
            :y="timelineHeight - 5" text-anchor="middle" font-size="12" fill="white">
            {{ month }}
          </text>

          <!-- Add separate year labels -->
          <text :x="labelWidth" :y="timelineHeight + 15" text-anchor="start" font-size="10" fill="white">
            {{ adjustedYear }}
          </text>
          <text :x="timelineWidth - padding" :y="timelineHeight + 15" text-anchor="end" font-size="10" fill="white">
            {{ adjustedYear + 1 }}
          </text>

          <!-- Events -->
          <g v-for="(event, index) in currentYearEvents" :key="event.name + index"
            :class="{ draggable: isEventVisible(event), 'stroke-white stroke-2': event === selectedEvent }"
            @mousedown="onDragStart($event, event)" @click="selectEvent(event)">
            <rect v-if="isEventVisible(event)" :x="getEventX(event)" :y="getEventY(event)"
              :width="Math.max(getEventWidth(event), 2)" height="20" :fill="getEventColor(event)" rx="2" />
            <rect v-if="isEventVisible(event)" :x="getEventX(event) + Math.max(getEventWidth(event), 2) - 5"
              :y="getEventY(event)" width="5" height="20" fill="white" rx="2"
              @mousedown.stop="onResizeStart($event, event)" class="resize-handle" />
          </g>

          <!-- Plus buttons -->
          <g v-for="(row, index) in rows" :key="'plus-' + index">
            <rect :x="timelineWidth - 25" :y="10 + index * 25" width="20" height="20" rx="4" fill="#212936"
              class="cursor-pointer hover:fill-opacity-80" @click="addEvent(row)" />
            <text :x="timelineWidth - 15" :y="24 + index * 25" text-anchor="middle" font-size="16" fill="white"
              class="cursor-pointer select-none" @click="addEvent(row)">+</text>
          </g>

          <!-- Weather Events (commented out) -->
          <!-- <g v-for="(event, index) in weatherEvents" :key="'weather-' + event.name + index">
            <rect v-if="isEventVisible(event)" :x="getEventX(event)" :y="getWeatherRowY()"
              :width="Math.max(getEventWidth(event), 2)" height="20" fill="#4A4A4A" rx="2" />
            <text v-if="isEventVisible(event) && getEventWidth(event) > 50"
              :x="getEventX(event) + Math.max(getEventWidth(event), 2) / 2" :y="getWeatherRowY() + 10" fill="white"
              font-size="10" text-anchor="middle" dominant-baseline="middle" class="no-highlight">
              {{ event.name }}
            </text>
          </g> -->

          <!-- Current date line and triangle (moved to the end) -->
          <g v-if="isCurrentDateVisible">
            <line :x1="currentDateX" :y1="0" :x2="currentDateX" :y2="timelineHeight - 20" stroke="red"
              stroke-width="1" />
            <polygon
              :points="`${currentDateX - 5},${timelineHeight - 20} ${currentDateX + 5},${timelineHeight - 20} ${currentDateX},${timelineHeight - 25}`"
              fill="red" />
          </g>
        </svg>
      </div>

      <!-- Information Card -->
      <div v-if="selectedEvent" class="bg-gray-800 p-2 mt-4 rounded-lg text-sm max-w-[200px]">
        <div class="flex items-center mb-2">
          <h3 v-if="!isEditing" class="text-md font-black font-lato mr-2">{{ selectedEvent.name }}</h3>
          <input v-else v-model="editedName"
            class="text-md font-black font-lato mr-2 bg-gray-700 text-white px-2 py-1 rounded">
          <button @click="toggleEdit" v-if="!isEditing" class="text-white hover:text-gray-300">
            <span class="mdi mdi-pencil"></span>
          </button>
          <button @click="confirmEdit" v-else class="text-white hover:text-gray-300">
            <span class="mdi mdi-check"></span>
          </button>
        </div>
        <div class="mb-2">
          <label class="block text-sm font-medium text-gray-400">Start Date</label>
          <input type="date" :value="formatDateForInput(getEventStartDate(selectedEvent))" @input="updateStartDate"
            class="bg-gray-700 text-white px-2 py-1 rounded w-full">
        </div>
        <div class="mb-2">
          <label class="block text-sm font-medium text-gray-400">End Date</label>
          <input type="date" :value="formatDateForInput(getEventEndDate(selectedEvent))" @input="updateEndDate"
            class="bg-gray-700 text-white px-2 py-1 rounded w-full">
        </div>
        <p>({{ formatDuration(selectedEvent.duration) }})</p>
        <div class="mt-2 flex justify-end">
          <button @click="saveChanges" class="bg-green-500 hover:bg-green-600 text-white px-2 rounded mr-2">
            ✓
          </button>
          <button @click="cancelChanges" class="bg-gray-500 hover:bg-gray-600 text-white px-2 rounded mr-2">
            ✗
          </button>
          <button @click="deleteEvent" class="bg-red-500 hover:bg-red-600 text-white px-2 rounded">
            <span class="mdi mdi-trash-can-outline"></span>
          </button>
        </div>
      </div>
    </div>

    <!-- Guidance Card -->
    <div v-if="type === 'outplant'" class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col relative">
      <h3 class="text-xl font-black font-lato mb-2">Guidance</h3>
      <button @click="showBuildCompliantScheduleConfirmation"
        class="absolute top-4 right-4 flex items-center text-sm bg-primary hover:bg-blue-600 text-white px-2 py-1 rounded">
        <span class="mdi mdi-creation mr-1"></span>
        Build Compliant Schedule
      </button>
      <ul class="text-sm">
        <li v-for="(guide, index) in guidanceWithCompliance" :key="index" class="mb-4">
          <div class="font-semibold">{{ guide.name }}:</div>
          <div>{{ guide.description }}</div>
          <div v-if="!guide.isCompliant" class="text-red-500">
            <div v-if="guide.neededEvents > 0">
              Need {{ guide.neededEvents }} more event(s)
            </div>
            <div v-for="(issue, issueIndex) in guide.spacingIssues" :key="issueIndex">
              {{ issue }}
            </div>
          </div>
          <div v-else class="text-green-500">
            Compliant
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { db_resto } from '../main';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import BackButton from './utils/BackButton.vue';

export default {
  name: 'Schedule',
  components: {
    BackButton
  },
  props: {
    branchId: {
      type: String,
      required: true
    },
    siteId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['nursery', 'outplant'].includes(value)
    }
  },
  data() {
    return {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      allEvents: [],
      // eventColors: ['#00f1a0', '#27bdf4', '#ffffff', '#ee756f', '#ffa500', '#ff69b4'], // Seeding, Full Monitoring, Bleach Monitoring, Outplanting, General, Cleaning
      timelineWidth: 0,
      timelineHeight: 0,
      padding: 40,
      draggingEvent: null,
      resizingEvent: null,
      dragStartX: 0,
      originalStart: 0,
      originalDuration: 0,
      currentDateX: null,
      svgKey: 0,
      isDragging: false,
      isResizing: false,
      labelWidth: 125, 
      rows: [],
      selectedEvent: null,
      isEditing: false,
      editedName: '',
      selectedYear: new Date().getFullYear(),
      availableYears: [],
      currentDate: new Date(),
      upcomingEvents: [],
      tempEvent: null,
      weatherEvents: [],
      guidance: [
        { 
          name: 'Surveys', 
          description: 'At least 1 survey annually', 
          frequency: 1, // 1 per year
          idealSpacing: 12, // 12 months between events
          spacingTolerance: 1, // 1 month tolerance
          duration: 7/30 // 1 week
        },
        { 
          name: 'Photogrammetry', 
          description: 'At least 1 photogrammetry annually', 
          frequency: 1, // 1 per year
          idealSpacing: 12, // 12 months between events
          spacingTolerance: 1, // 1 month tolerance
          duration: 7/30 // 1 week
        },
        { 
          name: 'Cell Monitoring', 
          description: 'Every 3 months', 
          frequency: 4, // 4 per year
          idealSpacing: 3, // 3 months between events
          spacingTolerance: 0.5, // 2 weeks in months
          duration: 7/30 // 1 week
        },
        { 
          name: 'Water Quality', 
          description: 'Every 1 month', 
          frequency: 12, // 12 per year
          idealSpacing: 1, // 1 month between events
          spacingTolerance: 0.25, // 1 week in months
          duration: 3/30 // 3 days
        },
      ],
      adjustedStartMonth: 0,
      adjustedMonths: [],
      adjustedYear: null,
      isCurrentDateVisible: false,
    };
  },
  created() {
    const currentYear = new Date().getFullYear();
    this.availableYears = [currentYear, currentYear + 1, currentYear + 2];
    this.initializeRows();
    this.fetchAllSchedulesFromFirestore();
    this.fetchWeatherEventsFromFirestore();
  },
  mounted() {
    this.updateTimelineWidth();
    this.calculateCurrentDatePosition();
    window.addEventListener('resize', this.updateTimelineWidth);
    window.addEventListener('mousemove', this.onDragMove);
    window.addEventListener('mousemove', this.onResizeMove);
    window.addEventListener('mouseup', this.onDragEnd);
    window.addEventListener('mouseup', this.onResizeEnd);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateTimelineWidth);
    window.removeEventListener('mousemove', this.onDragMove);
    window.removeEventListener('mousemove', this.onResizeMove);
    window.removeEventListener('mouseup', this.onDragEnd);
    window.removeEventListener('mouseup', this.onResizeEnd);
  },
  watch: {
    allEvents: {
      handler() {
        this.svgKey += 1; 
      },
      deep: true,
    },
    rows: {
      handler() {
        this.updateTimelineHeight();
        this.svgKey += 1; 
      },
      immediate: true
    }
  },
  computed: {
    currentYearEvents() {
      const startDate = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
      const endDate = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0); // Last day of the 12-month period

      return this.allEvents.filter(event => {
        const eventStart = this.getEventStartDate(event);
        const eventEnd = this.getEventEndDate(event);
        return (eventStart <= endDate && eventEnd >= startDate);
      });
    },
    guidanceWithCompliance() {
      const today = new Date();
      const oneYearFromNow = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

      return this.guidance.map(guide => {
        const eventType = guide.name.toLowerCase();
        const relevantEvents = this.allEvents
          .filter(event => 
            this.getEventTypeName(event).toLowerCase() === eventType &&
            this.getEventStartDate(event) >= today &&
            this.getEventStartDate(event) <= oneYearFromNow
          )
          .sort((a, b) => this.getEventStartDate(a) - this.getEventStartDate(b));
        
        const expectedEvents = guide.frequency;
        const actualEvents = relevantEvents.length;
        
        let isCompliant = actualEvents >= expectedEvents;
        let spacingIssues = [];
        
        if (relevantEvents.length > 1) {
          for (let i = 1; i < relevantEvents.length; i++) {
            const prevEventDate = this.getEventStartDate(relevantEvents[i - 1]);
            const currentEventDate = this.getEventStartDate(relevantEvents[i]);
            const monthsBetween = (currentEventDate - prevEventDate) / (30 * 24 * 60 * 60 * 1000);
            
            const idealSpacingMonths = 12 / guide.frequency;
            const minSpacing = idealSpacingMonths - guide.spacingTolerance;
            const maxSpacing = idealSpacingMonths + guide.spacingTolerance;
            
            if (monthsBetween < minSpacing) {
              spacingIssues.push(`Events on ${this.formatDate(prevEventDate)} and ${this.formatDate(currentEventDate)} are too close`);
              isCompliant = false;
            } else if (monthsBetween > maxSpacing) {
              spacingIssues.push(`Events on ${this.formatDate(prevEventDate)} and ${this.formatDate(currentEventDate)} are too far apart`);
              isCompliant = false;
            }
          }
        }
        
        const neededEvents = Math.max(0, expectedEvents - actualEvents);
        
        return {
          ...guide,
          isCompliant,
          neededEvents,
          spacingIssues
        };
      });
    }
  },
  methods: {
    initializeRows() {
      if (this.type === 'nursery') {
        this.rows = ['Seeding', 'Full Monitoring', 'Bleach Monitoring', 'Cleaning', 'Outplanting', 'General'];
      } else if (this.type === 'outplant') {
        this.rows = ['Surveys', 'Photogrammetry', 'Cell Monitoring', 'Water Quality', 'General'];
      }
      this.updateTimelineHeight();
    },
    async fetchAllSchedulesFromFirestore() {
      const collectionName = this.type === 'nursery' ? 'Nurseries' : 'OutplantSites';
      const siteRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/${collectionName}/${this.siteId}`);
      try {
        const docSnap = await getDoc(siteRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.schedules) {
            this.allEvents = this.availableYears.flatMap(year => 
              (data.schedules[year] || []).map(event => ({...event, year}))
            );
            this.calculateUpcomingEvents();
          } else {
            await updateDoc(siteRef, { schedules: { [this.selectedYear]: [] } });
          }
        }
      } catch (error) {
        console.error("Error fetching schedules:", error);
      }
    },
    async saveScheduleToFirestore() {
      const collectionName = this.type === 'nursery' ? 'Nurseries' : 'OutplantSites';
      const siteRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/${collectionName}/${this.siteId}`);
      try {
        const schedules = this.allEvents.reduce((acc, event) => {
          if (!acc[event.year]) acc[event.year] = [];
          acc[event.year].push(event);
          return acc;
        }, {});

        await updateDoc(siteRef, { schedules });
        
        this.calculateUpcomingEvents();
      } catch (error) {
        console.error("Error saving schedule:", error);
      }
    },
    updateTimelineWidth() {
      if (this.$refs.container) {
        this.timelineWidth = this.$refs.container.clientWidth - 32; 
        this.calculateCurrentDatePosition();
        this.adjustTimelineMonths();
      }
    },
    adjustTimelineMonths() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();

      if (this.selectedYear === currentYear && currentMonth >= 2) {
        this.adjustedStartMonth = Math.max(0, currentMonth - 2);
        this.adjustedYear = currentYear;
      } else {
        this.adjustedStartMonth = 0;
        this.adjustedYear = this.selectedYear;
      }

      const monthsThisYear = this.months.slice(this.adjustedStartMonth);
      const monthsNextYear = this.months.slice(0, this.adjustedStartMonth);
      this.adjustedMonths = [...monthsThisYear, ...monthsNextYear];
    },
    calculateCurrentDatePosition() {
      const today = new Date();
      const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
      const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
      
      if (today >= startOfTimeline && today <= endOfTimeline) {
        const daysSinceStart = Math.floor((today - startOfTimeline) / (24 * 60 * 60 * 1000));
        const totalDays = Math.floor((endOfTimeline - startOfTimeline) / (24 * 60 * 60 * 1000));
        const timelineStart = this.labelWidth;
        const timelineWidth = this.timelineWidth - this.labelWidth - this.padding;
        this.currentDateX = timelineStart + (daysSinceStart / totalDays) * timelineWidth;
        this.isCurrentDateVisible = true;
      } else {
        this.currentDateX = null;
        this.isCurrentDateVisible = false;
      }
    },
    getEventX(event) {
      const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
      const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
      
      const eventStart = this.getEventStartDate(event);
      const eventEnd = this.getEventEndDate(event);
      
      // If the event starts before the timeline, set the start to the beginning of the timeline
      const visibleStart = new Date(Math.max(eventStart, startOfTimeline));
      
      if (visibleStart > endOfTimeline) {
        return null; // Event is completely after the visible timeline
      }
      
      const daysSinceStart = (visibleStart - startOfTimeline) / (1000 * 60 * 60 * 24);
      const totalDays = (endOfTimeline - startOfTimeline) / (1000 * 60 * 60 * 24);
      return this.labelWidth + (daysSinceStart / totalDays) * (this.timelineWidth - this.labelWidth - this.padding);
    },
    getEventWidth(event) {
      const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
      const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
      
      const eventStart = this.getEventStartDate(event);
      const eventEnd = this.getEventEndDate(event);
      
      // Adjust event start and end if they're outside the visible timeline
      const visibleStart = new Date(Math.max(eventStart, startOfTimeline));
      const visibleEnd = new Date(Math.min(eventEnd, endOfTimeline));
      
      if (visibleStart >= endOfTimeline || visibleEnd <= startOfTimeline) {
        return 0; // Event is not visible on the timeline
      }
      
      const totalDays = (endOfTimeline - startOfTimeline) / (1000 * 60 * 60 * 24);
      const visibleDays = (visibleEnd - visibleStart) / (1000 * 60 * 60 * 24);
      
      return Math.max((visibleDays / totalDays) * (this.timelineWidth - this.labelWidth - this.padding), 2);
    },
    getEventY(event) {
      return 15 + event.level * 25; 
    },
    getEventColor(event) {
      const colorMap = {
        'Seeding': '#00f1a0',
        'Full Monitoring': '#27bdf4',
        'Bleach Monitoring': '#ffffff',
        'Outplanting': '#ee756f',
        'Cleaning': '#9B7FD4',
        'General': '#ffa500', // Orange color for General events
      };
      
      const eventType = this.rows[event.level].replace(/\s\d+$/, '');
      return colorMap[eventType] || this.eventColors[event.level % this.eventColors.length];
    },
    isEventVisible(event) {
      const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
      const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
      
      const eventStart = this.getEventStartDate(event);
      const eventEnd = this.getEventEndDate(event);

      return eventStart < endOfTimeline && eventEnd > startOfTimeline;
    },
    onDragStart(event, draggedEvent) {
      this.draggingEvent = draggedEvent;
      this.dragStartX = event.clientX;
      this.originalStart = this.getEventStartDate(draggedEvent);
      this.isDragging = true;
      document.body.style.userSelect = 'none';
      this.selectEvent(draggedEvent); 
    },
    onResizeStart(event, resizedEvent) {
      this.resizingEvent = resizedEvent;
      this.dragStartX = event.clientX;
      this.originalDuration = resizedEvent.duration;
      this.isResizing = true;
      document.body.style.userSelect = 'none';
    },
    onDragMove(event) {
      if (!this.draggingEvent) return;
      const deltaX = event.clientX - this.dragStartX;
      const dayWidth = (this.timelineWidth - this.labelWidth - this.padding) / 365;
      const dayDelta = Math.round(deltaX / dayWidth);
      
      const newStartDate = new Date(this.originalStart.getTime() + dayDelta * 24 * 60 * 60 * 1000);
      
      // Constrain the new date to be within the visible timeline
      const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
      const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
      
      // Calculate the end date of the event
      const eventDuration = this.draggingEvent.duration * 30 * 24 * 60 * 60 * 1000; // Convert duration to milliseconds
      const newEndDate = new Date(newStartDate.getTime() + eventDuration);
      
      if (newStartDate < startOfTimeline) {
        newStartDate.setTime(startOfTimeline.getTime());
      } else if (newEndDate > endOfTimeline) {
        newStartDate.setTime(endOfTimeline.getTime() - eventDuration);
      }
      
      // Update the event's year, start month, and day
      this.draggingEvent.year = newStartDate.getFullYear();
      this.draggingEvent.start = newStartDate.getMonth() + 1;
      this.draggingEvent.day = newStartDate.getDate();
      
      this.$forceUpdate();
    },
    onResizeMove(event) {
      if (!this.resizingEvent) return;
      const deltaX = event.clientX - this.dragStartX;
      const dayWidth = (this.timelineWidth - this.labelWidth - this.padding) / 365;
      const dayDelta = Math.round(deltaX / dayWidth);
      
      const startDate = this.getEventStartDate(this.resizingEvent);
      const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
      
      const maxDuration = (endOfTimeline - startDate) / (30 * 24 * 60 * 60 * 1000);
      
      const newDuration = Math.max(1/30, Math.min(this.originalDuration + dayDelta / 30, maxDuration));
      this.resizingEvent.duration = newDuration;
      
      this.$forceUpdate();
    },
    onDragEnd() {
      if (this.draggingEvent) {
        this.draggingEvent = null;
        this.isDragging = false;
        document.body.style.userSelect = '';
      }
    },
    onResizeEnd() {
      if (this.resizingEvent) {
        this.resizingEvent = null;
        this.isResizing = false;
        document.body.style.userSelect = '';
      }
    },
    updateTimelineHeight() {
      const dropdownHeight = 40; 
      const rowHeight = 25;
      const bottomPadding = 10;
      this.timelineHeight = (this.rows.length * rowHeight) + dropdownHeight + bottomPadding;
    },
    addEvent(rowName) {
      const today = new Date();
      
      let eventNumber = 1;
      let eventName = `${rowName} ${eventNumber}`;
      while (this.allEvents.some(e => e.name === eventName)) {
        eventNumber++;
        eventName = `${rowName} ${eventNumber}`;
      }
      
      const newEvent = {
        name: eventName,
        start: today.getMonth() + 1,
        day: today.getDate(),
        duration: 0.5,
        level: this.rows.indexOf(rowName),
        year: this.adjustedYear + (today.getMonth() < this.adjustedStartMonth ? 1 : 0)
      };

      this.allEvents.push(newEvent);
      this.selectEvent(newEvent);
      this.svgKey += 1;
      this.saveScheduleToFirestore();
    },
    selectEvent(event) {
      this.selectedEvent = event;
      this.tempEvent = { ...event }; 
      this.isEditing = false;
    },
    getEventTypeName(event) {
      return this.rows[event.level];
    },
    getEventStartDate(event) {
      return new Date(event.year, event.start - 1, event.day || 1);
    },
    getEventEndDate(event) {
      const startDate = this.getEventStartDate(event);
      const endDate = new Date(startDate.getTime() + event.duration * 30 * 24 * 60 * 60 * 1000);
      return endDate;
    },
    formatDate(date) {
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    },
    toggleEdit() {
      this.isEditing = true;
      this.editedName = this.selectedEvent.name;
    },

    confirmEdit() {
      if (this.editedName.trim() !== '') {
        this.selectedEvent.name = this.editedName.trim();
      }
      this.isEditing = false;
    },

    saveChanges() {
      this.saveScheduleToFirestore();
      this.selectedEvent = null;
      this.tempEvent = null;
      this.isEditing = false;
    },

    cancelChanges() {
      if (this.tempEvent) {
        const index = this.allEvents.findIndex(e => e === this.selectedEvent);
        if (index !== -1) {
          this.allEvents.splice(index, 1, this.tempEvent);
        }
      }
      this.selectedEvent = null;
      this.tempEvent = null;
      this.isEditing = false;
      this.svgKey += 1; // Force re-render of the SVG
    },

    deleteEvent() {
      const index = this.allEvents.indexOf(this.selectedEvent);
      if (index > -1) {
        this.allEvents.splice(index, 1);
      }
      this.selectedEvent = null;
      this.tempEvent = null;
      this.svgKey += 1;
      this.saveScheduleToFirestore();
    },
    onYearChange() {
      this.adjustTimelineMonths();
      this.fetchWeatherEventsFromFirestore();
      this.calculateCurrentDatePosition();
      this.svgKey += 1; 
    },
    getTimeUntilEvent(event) {
      const today = new Date();
      const eventStart = this.getEventStartDate(event);
      const timeDiff = eventStart - today;
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      if (daysDiff > 30) {
        const monthsDiff = Math.floor(daysDiff / 30);
        return `In ${monthsDiff} month${monthsDiff > 1 ? 's' : ''}`;
      } else if (daysDiff > 7) {
        const weeksDiff = Math.floor(daysDiff / 7);
        return `In ${weeksDiff} week${weeksDiff > 1 ? 's' : ''}`;
      } else {
        return `In ${daysDiff} day${daysDiff > 1 ? 's' : ''}`;
      }
    },
    calculateUpcomingEvents() {
      this.upcomingEvents = this.allEvents
        .filter(event => this.getEventStartDate(event) > this.currentDate)
        .sort((a, b) => this.getEventStartDate(a) - this.getEventStartDate(b))
        // .slice(0, 5);
    },
    formatDuration(duration) {
      if (duration >= 1) {
        const months = Math.floor(duration);
        const days = Math.round((duration - months) * 30);
        if (days > 0) {
          return `${months} month${months > 1 ? 's' : ''} ${days} day${days > 1 ? 's' : ''}`;
        } else {
          return `${months} month${months > 1 ? 's' : ''}`;
        }
      } else {
        const days = Math.round(duration * 30);
        return `${days} day${days > 1 ? 's' : ''}`;
      }
    },
    async fetchWeatherEventsFromFirestore() {
      const branchRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}`);
      try {
        const docSnap = await getDoc(branchRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.weatherEvents && data.weatherEvents[this.selectedYear]) {
            this.weatherEvents = data.weatherEvents[this.selectedYear];
          }
        }
      } catch (error) {
        console.error("Error fetching weather events:", error);
      }
    },
    getWeatherRowY() {
      return 15 + this.rows.length * 25;
    },
    formatDateForInput(date) {
      return date.toISOString().split('T')[0];
    },
    updateStartDate(event) {
      const newStartDate = new Date(event.target.value);
      const endDate = this.getEventEndDate(this.selectedEvent);
      
      this.selectedEvent.year = newStartDate.getFullYear();
      this.selectedEvent.start = newStartDate.getMonth() + 1;
      this.selectedEvent.day = newStartDate.getDate();
      
      // Recalculate duration
      const timeDiff = endDate - newStartDate;
      this.selectedEvent.duration = timeDiff / (30 * 24 * 60 * 60 * 1000);
      
      this.$forceUpdate();
    },
    updateEndDate(event) {
      const newEndDate = new Date(event.target.value);
      const startDate = this.getEventStartDate(this.selectedEvent);
      
      // Recalculate duration
      const timeDiff = newEndDate - startDate;
      this.selectedEvent.duration = timeDiff / (30 * 24 * 60 * 60 * 1000);
      
      this.$forceUpdate();
    },
    showBuildCompliantScheduleConfirmation() {
      if (confirm("This action will completely replace your current schedule. Are you sure you want to continue?")) {
        this.buildCompliantSchedule();
      }
    },
    buildCompliantSchedule() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);  // Set to start of day

      const oneYearFromTomorrow = new Date(tomorrow);
      oneYearFromTomorrow.setFullYear(oneYearFromTomorrow.getFullYear() + 1);
      
      let allEvents = {};

      this.guidance.forEach(guide => {
        const eventType = guide.name;
        const frequency = guide.frequency;
        const idealSpacing = guide.idealSpacing;
        const duration = guide.duration;

        let eventDate = new Date(tomorrow);
        for (let i = 0; i < frequency; i++) {
          const eventYear = eventDate.getFullYear();
          if (!allEvents[eventYear]) {
            allEvents[eventYear] = [];
          }
          
          allEvents[eventYear].push({
            name: `${eventType} ${i + 1}`,
            start: eventDate.getMonth() + 1,
            day: eventDate.getDate(),
            duration: duration,
            level: this.rows.indexOf(eventType),
            year: eventYear
          });

          // Calculate next event date
          eventDate = new Date(eventDate.getTime() + idealSpacing * 30 * 24 * 60 * 60 * 1000);
        }
      });

      // Sort events for each year
      Object.keys(allEvents).forEach(year => {
        allEvents[year].sort((a, b) => {
          const dateA = new Date(a.year, a.start - 1, a.day);
          const dateB = new Date(b.year, b.start - 1, b.day);
          return dateA - dateB;
        });
      });

      // Save all events to Firestore
      this.saveAllSchedulesToFirestore(allEvents);
    },

    async saveAllSchedulesToFirestore(allEvents) {
      const collectionName = this.type === 'nursery' ? 'Nurseries' : 'OutplantSites';
      const siteRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/${collectionName}/${this.siteId}`);
      try {
        await updateDoc(siteRef, { schedules: allEvents });
        this.allEvents = Object.entries(allEvents).flatMap(([year, events]) => 
          events.map(event => ({...event, year: parseInt(year)}))
        );
        this.calculateUpcomingEvents();
        this.svgKey += 1; // Force re-render of the SVG
      } catch (error) {
        console.error("Error saving schedules:", error);
      }
    }
  }
};
</script>

<style scoped>
.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.resize-handle {
  cursor: ew-resize;
}

.no-select {
  user-select: none;
}

.stroke-white {
  stroke: white;
}

.stroke-2 {
  stroke-width: 2px;
}

.timeline-container {
  position: relative;
  width: 100%;
  overflow: visible;
  margin-top: 10px; /* Add this line to create some space below the dropdown */
}

.no-highlight {
  user-select: none;
  pointer-events: none;
}
</style>
