<template>
    <div v-if="structure">
        <!-- Title and menu section -->
        <div class="flex items-center mb-4 ml-1">
            <BackButton class="mr-1" />
            <h1 class="text-2xl font-black font-lato mr-2">Structure {{ structure.position }}</h1>

            <div class="relative">
                <button @click="toggleMenu" class="text-white hover:text-gray-300 transition-colors duration-300">
                    <i class="mdi mdi-dots-vertical text-xl"></i>
                </button>
                <div v-if="showMenu"
                    class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
                    <div class="py-1 text-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem"
                            @click.prevent="editStructurePhotos">
                            <i class="mdi mdi-image-edit mr-2"></i>Edit Photos
                        </a>
                    </div>
                </div>
            </div>

            <!-- Stats Badge -->
            <div class="bg-black px-3 py-2 rounded-lg flex items-center text-xs">
                <div class="flex space-x-4">
                    <span>
                        <i class="mdi mdi-tree mr-1"></i>
                        {{ fragments.length }} fragments
                    </span>
                    <span v-if="avgHealth !== null">
                        <i class="mdi mdi-heart-pulse mr-1" style="color: #B9F6FC"></i>
                        <span style="color: #B9F6FC">{{ (convertScale(avgHealth) * 100).toFixed(0) }}% health</span>
                    </span>
                    <span v-if="avgBleach !== null">
                        <i class="mdi mdi-white-balance-sunny mr-1" style="color: #F9F5FE"></i>
                        <span style="color: #F9F5FE">{{ (convertScale(avgBleach) * 100).toFixed(0) }}% bleach</span>
                    </span>
                    <span>
                        <i class="mdi mdi-shield-check mr-1" style="color: #5EBAF0"></i>
                        <span style="color: #5EBAF0">{{ (survivalRate * 100).toFixed(0) }}% survival</span>
                    </span>
                    <span v-if="lastMonitoringDate">
                        <i class="mdi mdi-clock-outline mr-1"></i>
                        {{ formatLastMonitoring }}
                    </span>
                </div>
            </div>
        </div>



        <!-- Cover image section -->
        <div v-if="!editPhotosMode && (structure.cover_image || (structure.image_urls && structure.image_urls.length > 0))"
            class="mb-4 cursor-pointer" @click="editStructurePhotos">
            <img :src="structure.cover_image || structure.image_urls[0]"
                class="w-full max-h-[400px] object-cover rounded-lg"
                :alt="structure.cover_image ? 'Cover image' : 'Structure image 1'">
        </div>

        <!-- Edit Photos Component -->
        <StructureEditPhotos v-if="editPhotosMode" :branchId="branchId" :nurseryId="nurseryId" :structure="structure"
            @update="fetchStructureData" @close="editPhotosMode = false" />

        <div v-if="!editPhotosMode" class="overflow-x-auto">
            <div :class="[isMobile ? 'min-w-[1500px]' : 'min-w-[1000px]']"
                class="flex-grow flex flex-col-reverse justify-between bg-black rounded-lg shadow-md text-white min-w-[200px] min-h-[150px] cursor-pointer hover:shadow-lg transition-shadow duration-300 p-2 mb-4">
                <div v-for="i in structure.substructureCount" :key="i" class="relative h-6 mb-1 mt-1">
                    <div class="absolute top-1/2 left-0 right-0 h-px bg-white"></div>
                    <div v-for="fragment in fragmentsBySubstructure[i]" :key="fragment.id"
                        class="absolute top-1/2 w-6 h-6 rounded-full transform -translate-y-1/2 bg-black overflow-visible"
                        :style="{ left: `${(fragment.location.position / maxPosition) * 100 - 3}%` }"
                        @click="showFragmentInfo(fragment)">
                        <div class="w-4 h-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full"
                            :class="[hasData(fragment) ? 'border' : 'border border-dashed']" :style="{
                                backgroundColor: getBleachColor(fragment),
                                borderColor: getGenusColor(fragment.coral.genus)
                            }">
                            <div v-if="hasImages(fragment)"
                                class="w-1 h-1 bg-white rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            </div>
                        </div>
                        <svg v-if="hasHealthData(fragment)" viewBox="0 0 44 44"
                            class="w-full h-full absolute top-0 left-0">
                            <circle cx="22" cy="22" r="19" fill="none" :stroke="getHealthColor(fragment)"
                                stroke-width="6" stroke-dasharray="119.4 119.4"
                                :stroke-dashoffset="getHealthOffset(fragment)" transform="rotate(-90 22 22)" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <!-- Updated Legend -->
        <div v-if="!editPhotosMode" class="overflow-x-auto">
            <div class="p-3 rounded-lg text-white text-xs min-w-[600px]">
                <div class="flex justify-start space-x-4 h-14 relative">
                    <div class="flex flex-col w-24">
                        <div class="absolute top-0">Health</div>
                        <div class="relative w-full h-3 mt-6">
                            <div class="absolute w-full h-full rounded"
                                style="background: linear-gradient(to left, #FF0000, #FF7F00, #00FF00);">
                            </div>
                            <div class="absolute w-full flex justify-between px-0.5 mt-3">
                                <span>1</span>
                                <span>6</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-24">
                        <div class="absolute top-0">Bleaching</div>
                        <div class="relative w-full h-3 mt-6">
                            <div class="absolute w-full h-full rounded"
                                style="background: linear-gradient(to right, #000000, #FFFFFF);">
                            </div>
                            <div class="absolute w-full flex justify-between px-0.5 mt-3">
                                <span>1</span>
                                <span>6</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col items-start w-auto">
                        <div class="absolute top-0">Genus</div>
                        <div class="flex flex-wrap mt-6 gap-2">
                            <div v-for="(color, genus) in generaColors" :key="genus" class="flex ">
                                <div class="w-4 h-4 rounded-full border mr-1" :style="{ borderColor: color }"></div>
                                <span class="capitalize">{{ genus }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-12">
                        <div class="absolute top-0">No Data</div>
                        <div class="w-4 h-4 rounded-full border border-white border-dashed mt-6"></div>
                    </div>
                    <div class="flex flex-col w-16">
                        <div class="absolute top-0">Has Images</div>
                        <div class="w-4 h-4 rounded-full border border-white mt-6 relative">
                            <div
                                class="w-1 h-1 bg-white rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Fragment Details Modal -->
        <div v-if="selectedFragment" class="fixed inset-0 flex items-center justify-center z-50 p-4"
            @click.self="selectedFragment = null">
            <div
                class="bg-white/10 backdrop-blur text-white p-6 rounded w-full max-w-md max-h-[90vh] overflow-y-auto shadow-lg">
                <h2 class="text-xl font-black font-lato mb-4">Fragment Details</h2>
                <p class="mb-2"><strong>Substructure:</strong> {{ selectedFragment.location.substructure }}</p>
                <p class="mb-2"><strong>Position:</strong> {{ selectedFragment.location.position }}</p>
                <p class="mb-2"><strong>Health:</strong> {{ getLastHealth(selectedFragment) }}</p>
                <p class="mb-2"><strong>Bleaching:</strong> {{ getLastBleach(selectedFragment) }}</p>
                <p class="mb-2 capitalize"><strong>Genus:</strong> {{ selectedFragment.coral.genus || 'N/A' }}</p>
                <p class="mb-2"><strong>Species:</strong> {{ selectedFragment.coral.species || 'N/A' }}</p>

                <div v-if="lastHealthWithImages" class="mt-4">
                    <h3 class="font-black font-lato mb-2">Images:</h3>
                    <div class="flex flex-row space-x-4">
                        <div v-for="image in lastHealthWithImages.images" :key="image.side" class="flex-1 text-center">
                            <img :src="getImageUrl(image.original)" :alt="'Fragment ' + image.side + ' view'"
                                class="w-full h-auto rounded-lg mb-2 cursor-pointer"
                                @click="openFullScreenImage(image)">
                            <p class="capitalize">{{ image.side }} View</p>
                        </div>
                    </div>
                </div>

                <button @click="selectedFragment = null"
                    class="mt-4 bg-[#27bdf4] text-white px-4 py-2 rounded hover:bg-blue-500 transition-colors duration-200 w-full">
                    Close
                </button>
            </div>
        </div>

        <!-- Full Screen Image Modal -->
        <div v-if="fullScreenImage" class="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
            <img :src="getImageUrl(fullScreenImage.original)" :alt="'Full screen ' + fullScreenImage.side + ' view'"
                class="max-w-full max-h-full object-contain">
            <button @click="fullScreenImage = null"
                class="absolute top-4 right-4 text-white text-2xl font-black font-lato">&times;</button>
        </div>
    </div>
</template>

<script>
import { db_resto } from '../main';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import StructureCard from './StructureCard.vue';
import StructureEditPhotos from './StructureEditPhotos.vue';
import BackButton from './utils/BackButton.vue';

export default {
    name: 'Structure',
    components: {
        StructureCard,
        StructureEditPhotos,
        BackButton
    },
    props: {
        branchId: { type: String, required: true },
        nurseryId: { type: String, required: true },
        structureId: { type: String, required: true }
    },
    data() {
        return {
            structure: null,
            fragments: [],
            maxPosition: 0,
            selectedFragment: null,
            generaColors: {},
            fullScreenImage: null,
            showMenu: false,
            editPhotosMode: false,
        };
    },
    computed: {
        fragmentsBySubstructure() {
            const result = {};
            for (let i = 1; i <= (this.structure?.substructureCount || 0); i++) {
                result[i] = this.fragments.filter(f => f.location.substructure === i);
            }
            return result;
        },
        lastHealthWithImages() {
            if (this.selectedFragment && this.selectedFragment.healths) {
                const healthWithImages = [...this.selectedFragment.healths]
                    .reverse()
                    .find(health => health.images && health.images.length > 0);

                return healthWithImages || null;
            }
            return null;
        },
        isMobile() {
            return window.innerWidth < 768;
        },
        avgHealth() {
            return this.calculateAverage('health');
        },
        avgBleach() {
            return this.calculateAverage('bleach');
        },
        survivalRate() {
            const fragmentsWithHealth = this.fragments.filter(fragment =>
                fragment.healths && fragment.healths.length > 0 &&
                fragment.healths.some(h => h.health != null)
            );

            if (fragmentsWithHealth.length === 0) return 0;

            const survivingFragments = fragmentsWithHealth.filter(fragment => {
                const lastValidHealth = fragment.healths
                    .slice()
                    .reverse()
                    .find(h => h.health != null);
                return lastValidHealth && lastValidHealth.health !== 6;
            });

            return survivingFragments.length / fragmentsWithHealth.length;
        },
        lastMonitoringDate() {
            const allDates = this.fragments
                .flatMap(fragment => fragment.healths || [])
                .filter(health => health.created)
                .map(health => {
                    // Handle both Firestore Timestamp and regular Date objects
                    const date = health.created.toDate ? health.created.toDate() : health.created;
                    // Convert to local timezone
                    return new Date(date.toLocaleString());
                });
            
            return allDates.length > 0 ? Math.max(...allDates) : null;
        },
        formatLastMonitoring() {
            if (!this.lastMonitoringDate) return '';
            
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 
                           'July', 'August', 'September', 'October', 'November', 'December'];
            
            const date = new Date(this.lastMonitoringDate);
            const month = months[date.getMonth()];
            const day = date.getDate();
            const year = date.getFullYear();
            
            return `${month} ${day}, ${year}`;
        }
    },
    mounted() {
        this.fetchStructureData();
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.fetchStructureData();
            }
        }
    },
    methods: {
        openFullScreenImage(image) {
            this.fullScreenImage = image;
        },
        async fetchStructureData() {
            try {
                const structureRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}/Structures/${this.structureId}`);
                const structureDoc = await getDoc(structureRef);

                if (structureDoc.exists()) {
                    this.structure = { id: structureDoc.id, ...structureDoc.data() };
                    await this.fetchFragments();
                } else {
                    console.log('No such structure!');
                }
            } catch (error) {
                console.error("Error fetching structure data:", error);
            }
        },
        async fetchFragments() {
            try {
                const fragmentsRef = collection(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Fragments`);
                const q = query(
                    fragmentsRef,
                    where("location.nurseryID", "==", this.nurseryId),
                    where("location.structureID", "==", this.structureId)
                );
                const querySnapshot = await getDocs(q);

                this.fragments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                this.maxPosition = Math.max(...this.fragments.map(f => f.location.position));
                this.generateGeneraColors();
            } catch (error) {
                console.error("Error fetching fragments:", error);
            }
        },
        generateGeneraColors() {
            const genera = [...new Set(this.fragments.map(f => f.coral.genus))];
            const colors = [
                '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8',
                '#F06292', '#AED581', '#FFD54F', '#4DD0E1', '#BA68C8'
            ];
            this.generaColors = Object.fromEntries(genera.map((genus, index) =>
                [genus, colors[index % colors.length]]
            ));
        },
        getGenusColor(genus) {
            return this.generaColors[genus] || '#FFFFFF';  // Default to white if genus not found
        },
        getLastHealth(fragment) {
            const lastHealth = fragment.healths.findLast(h => h.health !== undefined);
            return lastHealth ? lastHealth.health : null; // Return null if no data
        },
        getLastBleach(fragment) {
            const lastBleach = fragment.healths.findLast(h => h.bleach !== undefined);
            return lastBleach ? lastBleach.bleach : null; // Return null if no data
        },
        getHealthOffset(fragment) {
            const health = this.getLastHealth(fragment);
            if (health === null) return 0; // No offset if no health data
            const healthPercentage = (6 - health) / 5;
            return (1 - healthPercentage) * 113.1;
        },
        getHealthColor(fragment) {
            const health = this.getLastHealth(fragment);
            if (health === null) return 'transparent'; // Return transparent if no health data
            const healthPercentage = (6 - health) / 5;

            const colors = [
                { offset: 0, color: '#FF0000' },
                { offset: 0.33, color: '#FF7F00' },
                { offset: 0.66, color: '#FFFF00' },
                { offset: 1, color: '#00FF00' }
            ];

            for (let i = 1; i < colors.length; i++) {
                if (healthPercentage <= colors[i].offset) {
                    const t = (healthPercentage - colors[i - 1].offset) / (colors[i].offset - colors[i - 1].offset);
                    return this.interpolateColor(colors[i - 1].color, colors[i].color, t);
                }
            }
            return colors[colors.length - 1].color;
        },
        getBleachColor(fragment) {
            const bleach = this.getLastBleach(fragment);
            if (bleach === null) return 'transparent'; // Return transparent if no bleach data
            const bleachPercentage = (bleach - 1) / 5;
            return this.interpolateColor('#000000', '#FFFFFF', bleachPercentage);
        },
        interpolateColor(color1, color2, t) {
            const r1 = parseInt(color1.slice(1, 3), 16);
            const g1 = parseInt(color1.slice(3, 5), 16);
            const b1 = parseInt(color1.slice(5, 7), 16);
            const r2 = parseInt(color2.slice(1, 3), 16);
            const g2 = parseInt(color2.slice(3, 5), 16);
            const b2 = parseInt(color2.slice(5, 7), 16);

            const r = Math.round(r1 + (r2 - r1) * t);
            const g = Math.round(g1 + (g2 - g1) * t);
            const b = Math.round(b1 + (b2 - b1) * t);

            return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
        },
        hasData(fragment) {
            return this.getLastHealth(fragment) !== null || this.getLastBleach(fragment) !== null;
        },
        hasHealthData(fragment) {
            return this.getLastHealth(fragment) !== null;
        },
        hasImages(fragment) {
            return fragment.healths.some(h => h.images && h.images.length > 0);
        },
        showFragmentInfo(fragment) {
            this.selectedFragment = fragment;
        },
        getImageUrl(originalUrl) {
            return `https://storage.googleapis.com/restoration-ios.appspot.com/coral-gardeners/${this.branchId}${originalUrl}`;
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        editStructurePhotos() {
            this.editPhotosMode = !this.editPhotosMode;
            this.showMenu = false;
        },
        calculateAverage(property) {
            const fragmentsWithData = this.fragments.filter(fragment =>
                fragment.healths &&
                fragment.healths.length > 0 &&
                fragment.healths.some(h => h[property] != null)
            );

            const scores = fragmentsWithData
                .map(fragment => {
                    const lastValidScore = fragment.healths
                        .slice()
                        .reverse()
                        .find(h => h[property] != null);
                    return lastValidScore ? lastValidScore[property] : null;
                })
                .filter(score => score !== null);

            if (scores.length === 0) return null;

            const sum = scores.reduce((acc, score) => acc + score, 0);
            const average = sum / scores.length;

            return average;
        },
        convertScale(value) {
            // Convert from 1-6 scale to 0-1 scale (inverted)
            return Math.max(0, Math.min(1, (6 - value) / 5));
        }
    },
}
</script>

<style scoped></style>