<template>
    <div class="bg-black text-white p-4 rounded-lg shadow-md flex flex-col">
        <div class="flex justify-between items-center">
            <h1 class="title text-left text-lg font-black font-lato">{{ chartTitle }}</h1>
            <button @click="toggleExpanded" class="text-white hover:text-gray-300 transition-colors duration-300">
                <i class="mdi" :class="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"></i>
            </button>
        </div>
        
        <!-- Chart content only shown when expanded -->
        <div v-if="expanded" class="mt-4">
            <div class="chart-container flex-grow relative flex justify-around items-center h-64">
                <canvas ref="chartRef" class="max-w-full max-h-full"></canvas>
            </div>
            <div v-if="!chartInstance" class="text-center py-8">
                Loading chart data...
            </div>
            <!-- Interactive legend with checkboxes -->
            <div class="custom-legend flex flex-wrap justify-center items-center mt-2 gap-4">
                <div v-for="(dataset, index) in datasets" :key="index" class="legend-item flex items-center">
                    <input 
                        type="checkbox" 
                        :id="`legend-checkbox-${index}`" 
                        :checked="isDatasetVisible(index)"
                        @change="toggleDataset(index)"
                        class="colored-checkbox mr-1"
                        :style="{ '--checkbox-color': dataset.borderColor }"
                    />
                    <span class="text-xs ml-1">{{ dataset.label }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    Chart, LineController, LineElement, PointElement, CategoryScale, LinearScale, TimeScale, // Added TimeScale
    Title, Tooltip, Legend, Filler
} from 'chart.js';
import 'chartjs-adapter-date-fns'; // Import the date adapter

// Register Chart.js components used
Chart.register(LineController, LineElement, PointElement, CategoryScale, LinearScale, TimeScale, Title, Tooltip, Legend, Filler);

export default {
    name: 'ComboChart',
    props: {
        data: {
            type: Object,
            required: true
        },
        chartTitle: {
            type: String,
            default: 'COMBINED'
        }
    },
    data() {
        return {
            chartInstance: null,
            datasets: [],
            expanded: false, // Default to collapsed state
            visibleDatasets: [] // Track which datasets are visible
        };
    },
    mounted() {
        // Only initialize chart when expanded for the first time
    },
    watch: {
        data: {
            deep: true,
            handler() {
                if (this.expanded) {
                    this.renderChart();
                }
            }
        },
        expanded(newValue) {
            if (newValue) {
                // Always re-render the chart when expanding
                this.$nextTick(() => {
                    this.renderChart();
                });
            }
        }
    },
    methods: {
        toggleExpanded() {
            this.expanded = !this.expanded;
        },
        // Check if dataset is visible
        isDatasetVisible(index) {
            return this.visibleDatasets.includes(index);
        },
        // Toggle dataset visibility
        toggleDataset(index) {
            const isVisible = this.isDatasetVisible(index);
            
            if (isVisible) {
                // Remove from visible datasets
                this.visibleDatasets = this.visibleDatasets.filter(i => i !== index);
            } else {
                // Add to visible datasets
                this.visibleDatasets.push(index);
            }
            
            this.updateChartVisibility();
        },
        // Update chart to reflect dataset visibility
        updateChartVisibility() {
            if (!this.chartInstance) return;
            
            this.datasets.forEach((dataset, index) => {
                const isVisible = this.isDatasetVisible(index);
                const chartDataset = this.chartInstance.data.datasets[index];
                if (chartDataset) {
                    chartDataset.hidden = !isVisible;
                }
            });
            
            this.chartInstance.update();
        },
        renderChart() {
            if (this.chartInstance) {
                this.chartInstance.destroy();
                this.chartInstance = null;
            }

            const history = this.data.nursery.history;
            // Get all dates from all datasets to ensure complete timeline
            let allDates = new Set();
            if (history.alive) Object.keys(history.alive).forEach(date => allDates.add(date));
            if (history.EVI) Object.keys(history.EVI).forEach(date => allDates.add(date));
            if (history.bleach) Object.keys(history.bleach).forEach(date => allDates.add(date));
            if (history.health) Object.keys(history.health).forEach(date => allDates.add(date));
            
            const dates = Array.from(allDates);
            
            if (dates.length === 0) return;

            dates.sort((a, b) => new Date(a) - new Date(b)); // Ensure dates are sorted

            this.datasets = [];

            // Add console logging to debug data
            console.log("ComboChart - Health Data:", history.health ? Object.keys(history.health).length : 0);
            console.log("ComboChart - Bleach Data:", history.bleach ? Object.keys(history.bleach).length : 0);
            console.log("ComboChart - EVI Data:", history.EVI ? Object.keys(history.EVI).length : 0);
            console.log("ComboChart - Alive Data:", history.alive ? Object.keys(history.alive).length : 0);

            // Survival (Percentage, Right Axis 1)
            if (history.alive && Object.keys(history.alive).length > 0) {
                this.datasets.push({
                    label: 'Survival',
                    borderColor: '#4ade80', // Green
                    backgroundColor: 'rgba(74, 222, 128, 0.2)',
                    data: dates.map(date => ({ x: new Date(date), y: history.alive[date] * 100 })),
                    yAxisID: 'yPercentage',
                    borderWidth: 2,
                    tension: 0.4,
                    fill: true,
                    pointRadius: 3,
                    pointBackgroundColor: '#4ade80',
                    pointBorderColor: '#fff',
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: '#4ade80',
                    spanGaps: true,
                    order: 4 // Higher order gets drawn behind
                });
            }

            // Growth (EVI, Right Axis 2)
            if (history.EVI && Object.keys(history.EVI).length > 0) {
                this.datasets.push({
                    label: 'Growth',
                    borderColor: '#60a5fa', // Blue
                    backgroundColor: 'rgba(96, 165, 250, 0.2)',
                    data: dates.map(date => ({ x: new Date(date), y: history.EVI[date] })),
                    yAxisID: 'yGrowth',
                    borderWidth: 2,
                    tension: 0.4,
                    fill: false, // Only fill Survival
                    pointRadius: 3,
                    pointBackgroundColor: '#60a5fa',
                    pointBorderColor: '#fff',
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: '#60a5fa',
                    spanGaps: true,
                    order: 3 // Higher order gets drawn behind
                });
            }

            // Bleaching (Score, Left Axis)
            if (history.bleach && Object.keys(history.bleach).length > 0) {
                 this.datasets.push({
                    label: 'Bleaching',
                    borderColor: 'white', 
                    backgroundColor: 'white',
                    data: dates.map(date => ({ 
                        x: new Date(date), 
                        y: history.bleach[date] !== undefined ? history.bleach[date] : null 
                    })),
                    yAxisID: 'yScore',
                    borderWidth: 2,
                    tension: 0.4,
                    fill: false,
                    pointRadius: 3,
                    pointBackgroundColor: 'white',
                    pointBorderColor: '#fff',
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: '#facc15',
                    spanGaps: true,
                    order: 2 // Higher order gets drawn behind
                });
            }

            // Health (Score, Left Axis)
            if (history.health && Object.keys(history.health).length > 0) {
                 this.datasets.push({
                    label: 'Health',
                    borderColor: '#f87171', // Red
                    backgroundColor: 'rgba(248, 113, 113, 0.2)',
                    data: dates.map(date => ({ 
                        x: new Date(date), 
                        y: history.health[date] !== undefined ? history.health[date] : null 
                    })),
                    yAxisID: 'yScore',
                    borderWidth: 2,
                    tension: 0.4,
                    fill: false,
                    pointRadius: 3,
                    pointBackgroundColor: '#f87171',
                    pointBorderColor: '#fff',
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: '#f87171',
                    spanGaps: true,
                    order: 1 // Lower order gets drawn on top
                });
            }
            
            if (this.datasets.length === 0) return;

            // --- DEBUGGING START ---
            console.log("ComboChart - Processed Dates:", dates);
            console.log("ComboChart - Generated Datasets:", this.datasets.map(d => d.label));
            // --- DEBUGGING END ---

            // Initialize all datasets as visible
            this.visibleDatasets = this.datasets.map((_, index) => index);

            const ctx = this.$refs.chartRef.getContext('2d');
            this.chartInstance = new Chart(ctx, {
                type: 'line',
                data: {
                    // labels are inferred from data points with time scale
                    datasets: this.datasets
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: false, // Match NurseryChart
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    stacked: false,
                    plugins: {
                        title: {
                            display: false, // Title is handled by the h1 element
                        },
                        legend: {
                            display: false, // Disable built-in legend, we're using our custom one
                        },
                        tooltip: {
                            displayColors: false,
                            callbacks: {
                                title: (tooltipItems) => {
                                    // Display date in tooltip title
                                    if (tooltipItems.length > 0) {
                                        const date = new Date(tooltipItems[0].parsed.x);
                                        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' });
                                    }
                                    return '';
                                },
                                label: function(context) {
                                    let label = context.dataset.label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    if (context.parsed.y !== null) {
                                        let value = context.parsed.y;
                                        if (context.dataset.yAxisID === 'yPercentage') {
                                            label += value.toFixed(1) + '%';
                                        } else if (context.dataset.yAxisID === 'yGrowth') {
                                            label += value.toFixed(1) + ' cm³';
                                        } else if (context.dataset.yAxisID === 'yScore') {
                                            label += value.toFixed(1);
                                        } else {
                                            label += value.toFixed(1);
                                        }
                                    }
                                    return label;
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'month',
                                displayFormats: {
                                    month: 'MMM yy'
                                }
                            },
                            title: { display: false },
                            ticks: {
                                color: '#FFFFFF',
                                maxRotation: 0,
                                minRotation: 0,
                                maxTicksLimit: 5, // Match NurseryChart
                            },
                            grid: this.getGridOptions()
                        },
                        yScore: { // Left Axis (Bleaching/Health)
                            type: 'linear',
                            display: true,
                            position: 'left',
                            min: 0,
                            max: 6,
                            title: {
                                display: true,
                                text: 'Score (0-6)',
                                color: '#ffffff'
                            },
                            ticks: { color: '#FFFFFF' },
                            grid: {
                                drawOnChartArea: false, // only want grid lines for primary y axis
                                color: 'rgba(255, 255, 255, 0.2)'
                            }
                        },
                        yPercentage: { // Right Axis 1 (Survival)
                            type: 'linear',
                            display: true,
                            position: 'right',
                            min: 0,
                            max: 100,
                            title: {
                                display: true,
                                text: 'Survival (%)',
                                color: '#ffffff'
                            },
                            ticks: {
                                color: '#FFFFFF',
                                callback: function(value) {
                                    return value + '%';
                                }
                            },
                            grid: this.getGridOptions() // Primary axis grid
                        },
                        yGrowth: { // Right Axis 2 (Growth)
                            type: 'linear',
                            display: true,
                            position: 'right',
                             title: {
                                display: true,
                                text: 'Growth (cm³)',
                                color: '#ffffff'
                            },
                            ticks: { color: '#FFFFFF' },
                            grid: {
                                drawOnChartArea: false,
                                color: 'rgba(255, 255, 255, 0.2)'
                            }
                        }
                    }
                }
            });
        },
        // Grid configuration helper (like in NurseryChart)
        getGridOptions() {
            return {
                color: 'rgba(255, 255, 255, 0.2)',
                borderColor: '#FFFFFF',
                borderWidth: 1,
                tickColor: '#FFFFFF',
                tickBorderDash: [5, 5]
            };
        }
    },
    beforeUnmount() {
        // Destroy the chart instance when the component is unmounted
        if (this.chartInstance) {
            this.chartInstance.destroy();
        }
    }
};
</script>

<style scoped>
/* Use scoped styles like NurseryChart if needed, keeping it minimal */
.chart-container {
    position: relative;
    width: 100%; 
}
/* Ensure canvas resizes correctly within flex container */
canvas {
    max-width: 100%;
    max-height: 100%;
}

/* Custom legend styles */
.custom-legend {
    margin-top: 4px;
}

.legend-item {
    margin: 0 8px;
    cursor: pointer;
}

/* Checkbox styling */
.colored-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border: 2px solid var(--checkbox-color, white);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    margin-right: 5px;
    background-color: transparent;
}

.colored-checkbox:checked {
    background-color: var(--checkbox-color, white);
}

.colored-checkbox:checked::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
</style> 