<template>
	<div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col min-w-[200px] relative"
			ref="container">
		<!-- Add title -->
		<h2 class="text-xl font-black font-lato mb-4">Schedule</h2>

		<div v-if="ongoingEvents.length > 0">
			<h3 class="text-sm font-semibold mb-2">Ongoing events</h3>
			<div class="flex flex-wrap justify-start mb-4">
				<div v-for="(event, index) in ongoingEvents" :key="'ongoing-' + index" class="flex items-center mr-4 mb-2">
					<div class="w-1 h-4 rounded-full mr-2" :style="{ backgroundColor: getEventColor(event) }"></div>
					<span class="text-xs">{{ stripTrailingDigits(event.name) }} ({{ formatDuration(event.remainingDuration) }} left)</span>
				</div>
			</div>
		</div>
		<div v-if="upcomingEvents.length > 0">
			<h3 class="text-sm font-semibold mb-2">Upcoming events</h3>
			<div class="flex flex-wrap justify-start mb-4">
				<div v-for="(event, index) in upcomingEvents" :key="'upcoming-' + index" class="flex items-center mr-4 mb-2">
					<div class="w-1 h-4 rounded-full mr-2" :style="{ backgroundColor: getEventColor(event) }"></div>
					<span class="text-xs">{{ stripTrailingDigits(event.name) }} in {{ event.timeUntil }}</span>
				</div>
			</div>
		</div>
		<div class="timeline-container flex-grow">
			<svg :width="timelineWidth" :height="timelineHeight">
				<!-- Grid lines -->
				<g>
					<!-- Horizontal row lines, including top line -->
					<line v-for="index in rows.length + 1" :key="'row-line-' + (index - 1)"
								:x1="labelWidth"
								:y1="10 + (index - 1) * 25"
								:x2="timelineWidth - padding"
								:y2="10 + (index - 1) * 25"
								stroke="#333333" stroke-width="1" opacity="0.5" />

					<!-- Vertical month lines -->
					<line v-for="(month, index) in adjustedMonths" :key="'grid-' + month"
								:x1="labelWidth + (index * (timelineWidth - labelWidth - padding) / adjustedMonths.length)"
								:y1="10"
								:x2="labelWidth + (index * (timelineWidth - labelWidth - padding) / adjustedMonths.length)"
								:y2="10 + rows.length * 25"
								stroke="#333333" stroke-width="1" opacity="0.5" />
				</g>

				<!-- Timeline -->
				<line :x1="labelWidth" :y1="timelineHeight - padding" :x2="timelineWidth - padding" :y2="timelineHeight - padding"
							stroke="white" stroke-width="1" />

				<!-- Month ticks -->
				<line v-for="(month, index) in adjustedMonths" :key="'tick-' + month + index"
							:x1="labelWidth + (index * (timelineWidth - labelWidth - padding) / adjustedMonths.length)"
							:y1="timelineHeight - padding"
							:x2="labelWidth + (index * (timelineWidth - labelWidth - padding) / adjustedMonths.length)"
							:y2="timelineHeight - padding - 5"
							stroke="white" stroke-width="1" />

				<!-- Month labels -->
				<text v-for="(month, index) in adjustedMonths" :key="month + index" 
							:x="labelWidth + (index * (timelineWidth - labelWidth - padding) / adjustedMonths.length)"
							:y="timelineHeight - 2" text-anchor="middle" font-size="12" fill="white">
					{{ month }}
				</text>

				<!-- Add year labels -->
				<text :x="labelWidth" :y="timelineHeight + 15" text-anchor="start" font-size="10" fill="white">
					{{ adjustedYear }}
				</text>
				<text :x="timelineWidth - padding" :y="timelineHeight + 15" text-anchor="end" font-size="10" fill="white">
					{{ adjustedYear + 1 }}
				</text>

				<!-- grayed out events -->
				<defs>
					<pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
						<path d="M-1,1 l2,-2
									 M0,4 l4,-4
									 M3,5 l2,-2" 
							style="stroke:#888; stroke-width:1" />
					</pattern>
				</defs>


				<!-- Regular Events -->
				<g v-for="(event, index) in visibleEvents" :key="event.name + index + event.year">
					<rect v-if="!event.isWeather" :x="getEventX(event)" :y="getEventY(event)"
								:width="Math.max(getEventWidth(event), 2)" height="20" :fill="getEventColor(event, false)" rx="2"
								@mouseenter="showTooltip($event, event)"
								@mouseleave="hideTooltip" />
				</g>

				<!-- Weather Events -->
				<g v-for="(event, index) in visibleEvents" :key="event.name + index + event.year + 'weather'">
					<template v-if="event.isWeather">
						<rect :x="getEventX(event)" :y="getEventY(event, true)"
									:width="Math.max(getEventWidth(event), 2)" height="20" :fill="getEventColor(event, true)" rx="2"
									@mouseenter="showTooltip($event, event)"
									@mouseleave="hideTooltip" />
						<text v-if="getEventWidth(event) > 50"
									:x="getEventX(event) + Math.max(getEventWidth(event), 2) / 2" 
									:y="getEventY(event, true) + 10"
									fill="white" font-size="10" text-anchor="middle" dominant-baseline="middle" class="no-highlight">
							{{ event.name }}
						</text>
					</template>
				</g>

				<!-- rectangle for the gray overlay with diagonal lines -->
					<rect v-if="currentDateX !== null"
						:x="labelWidth" :y="10" 
						:width="currentDateX - labelWidth" 
						:height="timelineHeight - padding - 10" 
						fill="url(#diagonalHatch)" 
						fill-opacity="0.4" 
					/>

				<!-- Current date line and triangle (moved to the end) -->
				<g v-if="currentDateX !== null">
					<line :x1="currentDateX" :y1="10" :x2="currentDateX" :y2="timelineHeight - padding"
								stroke="red" stroke-width="1" />
					<polygon :points="`${currentDateX - 5},${timelineHeight - padding} ${currentDateX + 5},${timelineHeight - padding} ${currentDateX},${timelineHeight - padding - 5}`"
								 fill="red" />
				</g>
			</svg>
			<!-- Custom Tooltip -->
			<div v-if="tooltipVisible" class="custom-tooltip" :style="tooltipStyle" v-html="tooltipContent"></div>
		</div>

		<!-- Color-coded legend -->
		<div class="flex flex-wrap mt-4 justify-start">
			<div v-for="(row, index) in rows" :key="index" class="flex items-center mb-2 mr-3">
				<div class="w-3 h-3 rounded-full mr-1" :style="{ backgroundColor: getEventColor({ level: index }) }"></div>
				<span class="text-xs">{{ row }}</span>
			</div>
		</div>

		<!-- Dropdown menu -->
		<div class="absolute top-2 right-4">
			<div class="relative">
				<button @click="toggleDropdown" class="text-white hover:text-gray-300 transition-colors duration-300">
					<i class="mdi mdi-dots-vertical text-xl"></i>
				</button>
				<div v-if="showDropdown" class="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600">
					<div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
						<a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem" @click.prevent="editSchedule">
							<i class="mdi mdi-pencil mr-2"></i>Edit Schedule
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { db_resto } from '../main';
import { doc, getDoc } from 'firebase/firestore';

export default {
	name: 'ScheduleCard',
	props: {
		branchId: {
			type: String,
			required: true
		},
		siteId: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: true,
			validator: (value) => ['nursery', 'outplant'].includes(value)
		},
	},
	data() {
		return {
			months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
			events: [],
			eventsNextYear: [],
			eventColors: ['#00f1a0', '#27bdf4', '#ffffff', '#ee756f', '#ffa500'], // Seeding, Full Monitoring, Bleach Monitoring, Outplanting, General
			timelineWidth: 0,
			timelineHeight: 0,
			labelWidth: 10,
			padding: 20,
			currentDateX: null,
			rows: [],
			selectedYear: new Date().getFullYear(),
			legendHeight: 50,
			showDropdown: false,
			tooltipVisible: false,
			tooltipContent: '',
			tooltipStyle: {
				top: '0px',
				left: '0px',
			},
			weatherEvents: [],
			weatherEventsNextYear: [],
			ongoingEvents: [],
			ongoingEventsHeight: 0,
			upcomingEventsHeight: 0,
			adjustedStartMonth: 0,
			adjustedMonths: [],
			adjustedYear: null,
		};
	},
	computed: {
		upcomingEvents() {
			const today = new Date();
			return this.visibleEvents
				.map(event => {
					const eventDate = new Date(event.year, event.start - 1, event.day || 1);
					const timeDiff = eventDate - today;
					const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
					
					if (daysDiff < 0) return null; // Event has passed

					const timeUntil = this.formatTimeUntil(daysDiff);
					const name = this.stripTrailingDigits(event.name);

					return {
						...event,
						name,
						timeUntil,
						date: eventDate
					};
				})
				.filter(event => event !== null)
				.sort((a, b) => a.date - b.date)
				.slice(0, 5);
		},
		visibleEvents() {
			const allEvents = [
				...this.events.map(e => ({ ...e, year: this.selectedYear })),
				...this.eventsNextYear.map(e => ({ ...e, year: this.selectedYear + 1 })),
				...this.weatherEvents.map(e => ({ ...e, year: this.selectedYear, isWeather: true })),
				...this.weatherEventsNextYear.map(e => ({ ...e, year: this.selectedYear + 1, isWeather: true }))
			];
			return allEvents.filter(this.isEventVisible);
		}
	},
	mounted() {
		this.init();
		window.addEventListener('resize', this.updateTimelineWidth);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateTimelineWidth);
	},
	watch: {
		siteId() {
			this.init();
		},
		events() {
			this.calculateOngoingAndUpcomingEvents();
		},
		weatherEvents() {
			this.calculateOngoingAndUpcomingEvents();
		}
	},
	methods: {
		init() {
			this.initializeRows();
			this.updateTimelineWidth();
			this.calculateCurrentDatePosition();
			this.fetchScheduleFromFirestore();
			this.fetchWeatherEventsFromFirestore();
			this.adjustTimelineMonths();
		},
		initializeRows() {
			if (this.type === 'nursery') {
				this.rows = ['Seeding', 'Full Monitoring', 'Bleach Monitoring', 'Cleaning', 'Outplanting', 'General'];
			} else if (this.type === 'outplant') {
				this.rows = ['Surveys', 'Photogrammetry', 'Cell Monitoring', 'Water Quality', 'General', 'Weather'];
			}
		},
		async fetchScheduleFromFirestore() {
			const collectionName = this.type === 'nursery' ? 'Nurseries' : 'OutplantSites';
			const siteRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/${collectionName}/${this.siteId}`);
			try {
				const docSnap = await getDoc(siteRef);
				if (docSnap.exists()) {
					const data = docSnap.data();
					if (data.schedules) {
						this.events = data.schedules[this.selectedYear] || [];
						this.eventsNextYear = data.schedules[this.selectedYear + 1] || [];
					}
				}
			} catch (error) {
				console.error("Error fetching schedule:", error);
			}
		},
		async fetchWeatherEventsFromFirestore() {
			const siteRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}`);
			try {
				const docSnap = await getDoc(siteRef);
				if (docSnap.exists()) {
					const data = docSnap.data();
					if (data.weatherEvents) {
						this.weatherEvents = data.weatherEvents[this.selectedYear] || [];
						this.weatherEventsNextYear = data.weatherEvents[this.selectedYear + 1] || [];
					}
				}
			} catch (error) {
				console.error("Error fetching weather events:", error);
			}
		},
		updateTimelineWidth() {
			if (this.$refs.container) {
				this.timelineWidth = this.$refs.container.clientWidth - 32;
				this.timelineHeight = (this.rows.length * 25) + (2 * this.padding);
				this.calculateCurrentDatePosition();
				this.adjustTimelineMonths();
			}
		},
		adjustTimelineMonths() {
			const currentDate = new Date();
			const currentMonth = currentDate.getMonth();
			const currentYear = currentDate.getFullYear();

			if (this.selectedYear === currentYear && currentMonth >= 2) {
				this.adjustedStartMonth = Math.max(0, currentMonth - 2);
				this.adjustedYear = currentYear;
			} else {
				this.adjustedStartMonth = 0;
				this.adjustedYear = this.selectedYear;
			}

			const monthsThisYear = this.months.slice(this.adjustedStartMonth);
			const monthsNextYear = this.months.slice(0, this.adjustedStartMonth);
			this.adjustedMonths = [...monthsThisYear, ...monthsNextYear];
		},
		calculateCurrentDatePosition() {
			const today = new Date();
			const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
			const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
			
			if (today >= startOfTimeline && today <= endOfTimeline) {
				const daysSinceStart = Math.floor((today - startOfTimeline) / (24 * 60 * 60 * 1000));
				const totalDays = Math.floor((endOfTimeline - startOfTimeline) / (1000 * 60 * 60 * 24));
				const timelineStart = this.labelWidth;
				const timelineWidth = this.timelineWidth - this.labelWidth - this.padding;
				this.currentDateX = timelineStart + (daysSinceStart / totalDays) * timelineWidth;
			} else if (today < startOfTimeline) {
				this.currentDateX = this.labelWidth;
			} else {
				this.currentDateX = this.timelineWidth - this.padding;
			}
		},
		getEventX(event) {
			const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
			const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
			
			const eventStart = this.getEventStartDate(event);
			const eventEnd = this.getEventEndDate(event);
			
			const visibleStart = new Date(Math.max(eventStart, startOfTimeline));
			
			if (visibleStart > endOfTimeline) {
				return null;
			}
			
			const daysSinceStart = (visibleStart - startOfTimeline) / (1000 * 60 * 60 * 24);
			const totalDays = (endOfTimeline - startOfTimeline) / (1000 * 60 * 60 * 24);
			return this.labelWidth + (daysSinceStart / totalDays) * (this.timelineWidth - this.labelWidth - this.padding);
		},
		getEventWidth(event) {
			const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
			const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
			
			const eventStart = this.getEventStartDate(event);
			const eventEnd = this.getEventEndDate(event);
			
			const visibleStart = new Date(Math.max(eventStart, startOfTimeline));
			const visibleEnd = new Date(Math.min(eventEnd, endOfTimeline));
			
			if (visibleStart >= endOfTimeline || visibleEnd <= startOfTimeline) {
				return 0;
			}
			
			const totalDays = (endOfTimeline - startOfTimeline) / (1000 * 60 * 60 * 24);
			const visibleDays = (visibleEnd - visibleStart) / (1000 * 60 * 60 * 24);
			
			return Math.max((visibleDays / totalDays) * (this.timelineWidth - this.labelWidth - this.padding), 2);
		},
		getEventY(event, isWeather = false) {
			if (isWeather) {
				return 15 + (this.rows.length - 1) * 25;
			}
			return 15 + event.level * 25;
		},
		getEventColor(event) {
			const colorMap = {
				'Seeding': '#00f1a0',
				'Full Monitoring': '#27bdf4',
				'Bleach Monitoring': '#ffffff',
				'Cleaning': '#9B7FD4',
				'Outplanting': '#ee756f',
				'Surveys': '#00f1a0',
				'Photogrammetry': '#27bdf4',
				'Cell Monitoring': '#ffffff',
				'Water Quality': '#ee756f',
				'General': '#ffa500',
				'Weather': '#4A4A4A'
			};
			
			// Check if it's a weather event
			if (event.isWeather) {
				return colorMap['Weather'];
			}
			
			// For regular events
			if (typeof event.level === 'number') {
				const eventType = this.rows[event.level].replace(/\s\d+$/, '');
				return colorMap[eventType] || this.eventColors[event.level % this.eventColors.length];
			}
			
			// Default color if no match is found
			return '#ffa500';
		},
		isEventVisible(event) {
			const startOfTimeline = new Date(this.adjustedYear, this.adjustedStartMonth, 1);
			const endOfTimeline = new Date(this.adjustedYear + 1, this.adjustedStartMonth, 0);
			
			const eventStart = this.getEventStartDate(event);
			const eventEnd = this.getEventEndDate(event);

			return eventStart < endOfTimeline && eventEnd > startOfTimeline;
		},
		toggleDropdown() {
			this.showDropdown = !this.showDropdown;
		},
		editSchedule() {
			this.showDropdown = false;
			this.$router.push({
				name: 'Schedule',
				params: {
					branchId: this.branchId,
					siteId: this.siteId,
					type: this.type
				}
			});
		},
		showTooltip(event, eventData) {
			const rect = event.target.getBoundingClientRect();
			const containerRect = this.$refs.container.getBoundingClientRect();

			this.tooltipContent = this.getEventTooltip(eventData);

			let top = rect.top - containerRect.top - 5;

			this.tooltipStyle = {
				top: `${top}px`,
				left: `${rect.left - containerRect.left + rect.width / 2}px`,
			};
			this.tooltipVisible = true;
		},
		hideTooltip() {
			this.tooltipVisible = false;
		},
		getEventTooltip(event) {
			const startDate = new Date(event.year, event.start - 1, event.day || 1);
			const endDate = new Date(startDate);
			endDate.setDate(endDate.getDate() + event.duration - 1);
			const formattedStartDate = this.formatDate(startDate);
			const formattedEndDate = this.formatDate(endDate);
			const duration = this.formatDuration(event.duration);

			return `
				<strong>${event.name}</strong><br>
				${formattedStartDate} to ${formattedEndDate}<br>
				(${duration})
			`;
		},
		formatDate(date) {
			const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return `${months[date.getMonth()]} ${date.getDate()}`;
		},
		formatDuration(days) {
			if (days < 7) {
				return `${days} day${days > 1 ? 's' : ''}`;
			} else if (days < 30) {
				const weeks = Math.floor(days / 7);
				return `${weeks} week${weeks > 1 ? 's' : ''}`;
			} else {
				const months = Math.floor(days / 30);
				return `${months} month${months > 1 ? 's' : ''}`;
			}
		},
		getWeatherRowY() {
			return 15 + (this.rows.length - 1) * 25;
		},
		calculateOngoingAndUpcomingEvents() {
			const today = new Date();
			const allEvents = [...this.events, ...this.eventsNextYear, ...this.weatherEvents, ...this.weatherEventsNextYear];
			
			this.ongoingEvents = [];

			allEvents.forEach(event => {
				const eventStartDate = new Date(event.year, event.start - 1, event.day || 1);
				const eventEndDate = new Date(eventStartDate);
				eventEndDate.setMonth(eventEndDate.getMonth() + Math.floor(event.duration));
				eventEndDate.setDate(eventEndDate.getDate() + Math.round((event.duration % 1) * 30) - 1);

				if (today >= eventStartDate && today <= eventEndDate) {
					// Ongoing event
					const remainingDuration = (eventEndDate - today) / (1000 * 60 * 60 * 24 * 30); // Convert to months
					this.ongoingEvents.push({
						...event,
						remainingDuration,
					});
				}
			});

			// Sort ongoing events by remaining duration
			this.ongoingEvents.sort((a, b) => a.remainingDuration - b.remainingDuration);

			// Calculate heights based on the number of events
			this.ongoingEventsHeight = this.ongoingEvents.length > 0 ? 20 + (Math.ceil(this.ongoingEvents.length / 2) * 25) : 0;
			this.upcomingEventsHeight = this.upcomingEvents.length > 0 ? 20 + (Math.ceil(this.upcomingEvents.length / 2) * 25) : 0;
		},
		stripTrailingDigits(name) {
			return name.replace(/\s+\d+$/, '');
		},
		formatDuration(duration) {
			if (duration >= 1) {
				const months = Math.floor(duration);
				const days = Math.round((duration - months) * 30);
				if (days > 0) {
					return `${months} month${months > 1 ? 's' : ''} ${days} day${days > 1 ? 's' : ''}`;
				} else {
					return `${months} month${months > 1 ? 's' : ''}`;
				}
			} else {
				const days = Math.round(duration * 30);
				return `${days} day${days > 1 ? 's' : ''}`;
			}
		},
		formatTimeUntil(daysDiff) {
			if (daysDiff === 0) return 'Today';
			if (daysDiff < 7) return `${daysDiff} day${daysDiff > 1 ? 's' : ''}`;
			if (daysDiff < 30) {
				const weeks = Math.floor(daysDiff / 7);
				return `${weeks} week${weeks > 1 ? 's' : ''}`;
			}
			const months = Math.floor(daysDiff / 30);
			return `${months} month${months > 1 ? 's' : ''}`;
		},
		getEventStartDate(event) {
			const year = event.year || this.selectedYear;
			return new Date(year, event.start - 1, event.day || 1);
		},
		getEventEndDate(event) {
			const startDate = this.getEventStartDate(event);
			const endDate = new Date(startDate);
			endDate.setMonth(endDate.getMonth() + Math.floor(event.duration));
			endDate.setDate(endDate.getDate() + Math.round((event.duration % 1) * 30) - 1);
			return endDate;
		},
	},
};
</script>

<style scoped>
.timeline-container {
	position: relative;
	width: 100%;
	overflow: visible;
	margin-top: 10px;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.custom-tooltip {
	position: absolute;
	background: rgba(0, 0, 0, 0.8);
	color: white;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 12px;
	pointer-events: none;
	transform: translate(-50%, -100%);
	z-index: 1000;
	white-space: nowrap;
}

.custom-tooltip::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

/* Add flex properties to handle dynamic content */
.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-grow {
	flex-grow: 1;
}

/* Optional: Prevent overflow issues */
.timeline-container svg {
	width: 100%;
	height: auto;
}
</style>
