<template>
    <button @click="goBack" 
            class="flex items-center text-white hover:text-gray-300 transition-colors duration-300 font-lato">
        <span class="mdi mdi-arrow-left text-xl"></span>
        <!-- <span class="text-sm font-black uppercase">Back</span> -->
    </button>
</template>

<script>
export default {
    name: 'BackButton',
    methods: {
        goBack() {
            window.history.back();
        }
    }
}
</script> 