<template>
    <div ref="container" class="w-full h-full min-h-[200px] relative" :style="{
        borderTopRightRadius: isMobile ? '0' : '0.5rem',
        borderBottomRightRadius: '0.5rem',
        borderBottomLeftRadius: isMobile ? '0.5rem' : '0'
    }">
        <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center z-10">
            <div class="spinner-border animate-spin w-12 h-12 border-4 rounded-full" style="border-color: #27bdf4 transparent #27bdf4 transparent"></div>
        </div>
        <div v-if="hasError" class="absolute inset-0 flex items-center justify-center z-10">
            <div class="bg-red-50 border border-red-200 rounded-lg p-4 shadow-md">
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <span class="text-red-700 font-medium">Model not found</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export default {
    name: 'Photogrammetry',
    props: ['url', 'modelTransform'],
    data() {
        return {
            scene: null,
            camera: null,
            renderer: null,
            controls: null,
            object: null,
            isLoading: true,
            hasError: false
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initThree();
            this.animate();
            window.addEventListener('resize', this.handleResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
        if (this.renderer) {
            this.renderer.dispose();
        }
        this.scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
                child.material.dispose();
            }
        });
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        }
    },
    methods: {
        initThree() {
            this.scene = new THREE.Scene();
            this.camera = new THREE.PerspectiveCamera(50, this.$refs.container.clientWidth / this.$refs.container.clientHeight, 0.1, 1000);
            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.setSize(this.$refs.container.clientWidth, this.$refs.container.clientHeight);
            this.$refs.container.appendChild(this.renderer.domElement);

            if (this.isMobile) {
                this.renderer.domElement.style.borderBottomLeftRadius = '0.5rem';
                this.renderer.domElement.style.borderBottomRightRadius = '0.5rem';
            } else {
                this.renderer.domElement.style.borderTopRightRadius = '0.5rem';
                this.renderer.domElement.style.borderBottomRightRadius = '0.5rem';
            }
            

            // Add lighting
            const ambientLight = new THREE.AmbientLight(0xffffff, 2);
            this.scene.add(ambientLight);
            const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight.position.set(10, 10, 10);
            this.scene.add(directionalLight);

            // Add OrbitControls
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.enableZoom = true;

            const radius = 1.75; // Distance from the center of the scene
            const angle = Math.PI / 4; // 45 degrees in radians
            this.camera.position.x = radius * Math.cos(angle); // Assuming model centered at origin
            this.camera.position.y = radius * Math.sin(angle);
            this.camera.position.z = radius * Math.cos(angle);
            this.camera.lookAt(this.scene.position);

            // Load the OBJ file
            const objLoader = new OBJLoader();
            const textureLoader = new THREE.TextureLoader();

            this.isLoading = true;
            this.hasError = false;
            
            textureLoader.load(
                `${this.url}/model_medium.jpg`, 
                (texture) => {
                    objLoader.load(
                        `${this.url}/model_medium.obj`, 
                        (obj) => {
                            this.object = obj;
                            this.object.traverse((child) => {
                                if (child instanceof THREE.Mesh) {
                                    child.material.map = texture;
                                }
                            });
                            if (this.modelTransform) {
                                this.object.position.set(this.modelTransform.translation.x, this.modelTransform.translation.y, this.modelTransform.translation.z);
                                this.object.rotation.set(this.modelTransform.rotation.x, this.modelTransform.rotation.y, this.modelTransform.rotation.z);
                                this.object.scale.set(this.modelTransform.scale.x, this.modelTransform.scale.y, this.modelTransform.scale.z);
                            }
                            this.scene.add(this.object);

                            // Center the object
                            const box = new THREE.Box3().setFromObject(this.object);
                            const center = box.getCenter(new THREE.Vector3());
                            this.object.position.sub(center);
                            
                            // Hide the loading spinner when model is loaded
                            this.isLoading = false;
                        },
                        // onProgress callback
                        undefined,
                        // onError callback
                        (error) => {
                            console.error('Error loading OBJ file:', error);
                            this.isLoading = false;
                            this.hasError = true;
                        }
                    );
                }, 
                // onProgress callback
                undefined,
                // onError callback
                (error) => {
                    console.error('Error loading texture:', error);
                    this.isLoading = false;
                    this.hasError = true;
                }
            );
        },
        animate() {
            requestAnimationFrame(this.animate);
            if (this.controls) {
                this.controls.update();
            }
            if (this.renderer && this.scene && this.camera) {
                this.renderer.render(this.scene, this.camera);
            }
        },
        handleResize() {
            this.camera.aspect = this.$refs.container.clientWidth / this.$refs.container.clientHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.$refs.container.clientWidth, this.$refs.container.clientHeight);
        }
    },
    
};
</script>

<style scoped>
@keyframes spin {
    to { transform: rotate(360deg); }
}
.animate-spin {
    animation: spin 1s linear infinite;
}
</style>